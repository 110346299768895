import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../context/auth'
import notify from '../../../components/Notify'
import ResendVerifyEmailForm from './ResendVerifyEmailForm'

export default ({ message }) => {
  const { sendVerifyEmail } = useAuth()
  const [error, setError] = useState(null)
  const handleSubmit = async ({ email }, { setSubmitting }) => {
    setSubmitting(true)
    try {
      await sendVerifyEmail({ email })
      notify.info('an email has been sent')
    } catch (e) {
      if (error && Array.isArray(error.graphQLErrors) && error.graphQLErrors.length) {
        setError(error.graphQLErrors[0].message)
      }
    } finally {
      setSubmitting(false)
    }
  }

  if (message === 'user already verified') {
    return (
      <Container>
        <Row className="mt-5">
          <h3 className="text-center main-color text-capitalize">{message}</h3>
          <h6 className="text-center main-color">
            Navigate to
            {' '}
            <Link to="/auth/signin">login</Link>
          </h6>
        </Row>
      </Container>
    )
  }

  return (
    <Container>
      <Row className="mt-5">
        <h3 className="text-center main-color text-capitalize">{message}</h3>
        <h6 className="text-center main-color mt-3">
          Please check your inbox for verification email. Sometimes it ends up in the spam box.
        </h6>
        <h6 className="text-center main-color">You also can resend the email</h6>
      </Row>
      <Row className="mt-3">
        <Col md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }}>
          <Formik
            onSubmit={handleSubmit}
            initialValues={{ email: '' }}
            validationSchema={Yup.object({
              email: Yup.string().email().required('Email is required'),
            })}
          >
            {(props) => <ResendVerifyEmailForm statusError={error} {...props} />}
          </Formik>
        </Col>
      </Row>
    </Container>
  )
}
