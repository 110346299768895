import React from 'react'
import {
  QuestionCircle, BoxArrowUpRight, FilePlay,
} from 'react-bootstrap-icons'
import {
  Card, Col, Image, ListGroup, Row,
} from 'react-bootstrap'
import FAQIcon from '../../../assets/images/faq.svg'

export const StarterLinks = () => (
  <Card className="h-100">
    <Card.Body className="m-3">
      <Row>
        <Image className="h-100" id="logo" alt="Earn with Earn" src={FAQIcon} />
      </Row>
      <Row className="mt-1">
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Row
              as="a"
              className="plain d-flex align-items-center"
              href="https://www.spider.com/income-faq/how-to-get-started"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Col className="d-flex align-items-center">
                <FilePlay />
                <span className="ms-2">How to get started?</span>
              </Col>
              <Col lg={1} xs={2} md={2}>
                <BoxArrowUpRight className="float-end" />
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row
              as="a"
              className="plain d-flex align-items-center"
              href="https://www.spider.com/income/faq"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Col className="d-flex align-items-center">
                <QuestionCircle />
                <span className="ms-2">Frequently asked questions</span>
              </Col>
              <Col lg={1} xs={2} md={2}>
                <BoxArrowUpRight className="float-end" />
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </Row>
    </Card.Body>
  </Card>
)
