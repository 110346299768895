import React from 'react'
import { Spinner } from 'react-bootstrap'
import classnames from 'classnames'

const Loader = ({ className }) => (
  <div className={classnames('text-center', className)}>
    <Spinner animation="border" variant="primary" />
  </div>
)

export default Loader
