import React from 'react'
import Helmet from 'react-helmet'
import { siteMetadata } from '../config'

export default ({ title, description }) => (
  <Helmet
    htmlAttributes={{
      lang: 'en',
    }}
    title={title}
    titleTemplate={`%s | ${siteMetadata.title}`}
  >
    <meta name="description" content={description || siteMetadata.description} />
    <meta name="og:title" content={title} />
    <meta name="og:description" content={description || siteMetadata.description} />
    <meta name="keywords" content={(siteMetadata.keywords || []).join(', ')} />
  </Helmet>
)
