import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Layout from './components/Layout'
import Header from '../../components/Header'
import TopBar from '../layout/Topbar'
import AuthLinks from '../layout/components/AuthLinks'
import ResendVerifyEmail from './components/ResendVerifyEmail'

const ResendVerificationEmail = () => (
  <Layout>
    <Container>
      <Header title="Sign up" />
      <TopBar>
        <AuthLinks />
      </TopBar>
      <Row className="mt-5">
        <Col md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }}>
          <ResendVerifyEmail message="Resend verification email" />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ResendVerificationEmail
