import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import fileDownload from 'js-file-download'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import Cookies from 'js-cookie'
import Layout from '../auth/components/Layout'
import Header from '../../components/Header'
import TopBar from '../layout/Topbar'
import Loader from '../../components/Loader'
import { getAPIUrl } from '../../config'
import AuthLinks from '../layout/components/AuthLinks'

export default () => {
  const location = useLocation()
  const { refCode } = queryString.parse(location.search)
  const [fileDownloadStarted, setFileDownloadStarter] = useState(false)
  const [downloadURL, setDownloadURL] = useState('test')
  if (refCode) {
    Cookies.set('refCode', refCode, { expires: 100, path: '/' })
  }

  useEffect(() => {
    fetch(`${getAPIUrl()}/v1/version`)
      .then((response) => response.text())
      .then((content) => {
        const url = JSON.parse(content).win
        setDownloadURL(url)
        return fetch(url)
          .then((response) => response.blob())
          .then((installer) => {
            fileDownload(installer, 'IncomeInstaller.exe')
            setFileDownloadStarter(true)
          })
      }).catch((error) => {
        console.log('Request failed', error)
      })
  }, [])

  return (
    <Layout plain>
      <Container>
        <Header title="Sign in" />
        <TopBar>
          <AuthLinks />
        </TopBar>
        <Row className="mt-5 mb-4">
          <Col className="text-center" md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }}>
            <h5 className="blue-color mb-2">
              {`${!fileDownloadStarted ? 'Preparing Windows' : 'Saving an'}  installer for you`}
            </h5>
            {!fileDownloadStarted && <h6 className="blue-color mb-2">Hit Save when installer is ready</h6>}
            {!fileDownloadStarted && <Loader className="mt-4" />}
          </Col>
        </Row>
        {downloadURL && (
          <Row className="mt-4">
            <Col className="text-center" md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }}>
              <h6 className="blue-color mb-2">
                Click&nbsp;
                <a target="_blank" rel="noopener noreferrer" href={downloadURL}>download</a>
                &nbsp;
                if not started automatically
              </h6>
            </Col>
          </Row>
        )}
      </Container>
    </Layout>
  )
}
