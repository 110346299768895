import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './Layout.css'
import { ToastContainer } from 'react-toastify'
import Sidebar from './Sidebar'

const Layout = ({ children, plain }) => (
  <Container fluid className="h-100">
    <Row className="h-100">
      <Col className="sidebar d-none d-md-block" lg={3} md={3}>
        <Sidebar plain={plain} />
      </Col>
      <Col lg={9} md={9}>
        {children}
      </Col>
    </Row>
    <ToastContainer />
  </Container>
)

export default Layout
