import React, { useState } from 'react'
import {
  Alert, Button, Col, Row,
} from 'react-bootstrap'
import Cookies from 'js-cookie'
import { gql, useMutation } from '@apollo/client'
import notify from '../../../components/Notify'
import { gqlErrorOr } from '../../../utils'

const MUTATION = gql`
    mutation UpdateInvite($id: Int64!, $status: TeamInviteStatus!) {
        updateInvite(id: $id, status: $status)
    }
`

const TeamInviteAlert = ({ teamInvite: { id, team, inviter }, refetchQueries }) => {
  const [updateInvite] = useMutation(MUTATION)
  const [visible, setVisible] = useState(true)
  const snoozed = Cookies.get('teamInvite.snoozed')
  if (snoozed || !visible) {
    return null
  }

  const snoozeAlert = () => {
    Cookies.set('teamInvite.snoozed', true, { expires: 3, path: '/' })
    setVisible(false)
  }

  const updateInviteTo = async (status) => {
    try {
      await updateInvite({ variables: { id, status }, refetchQueries })
      notify.info(`invite has been ${status}`)
    } catch (e) {
      notify.error(gqlErrorOr(e, 'ooops something went wrong'))
    }
  }

  const acceptInvite = async () => updateInviteTo('accepted')
  const declineInvite = async () => updateInviteTo('rejected')

  return (
    <Alert>
      <Row>
        <h5 className="text-black">
          {`Invite to team ${team.name}`}
        </h5>
      </Row>
      <Row>
        <p>
          {
            `${inviter.name} invited you to the team ${team.name}, the purpose of the team is "${team.bio}". `
            + 'All your direct and referral bandwidth income will be donated to the team. '
            + 'Once you join, you can choose to donate less of your direct direct income on the team view page. '
            + 'Bonus income will not be donated. '
            + 'As a community of Earners you can raise a lot of money for your team\'s cause!'
          }
        </p>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <Button className="me-2 btn-lg" variant="secondary" onClick={snoozeAlert}>Dismiss & Snooze</Button>
          <Button className="me-2 btn-lg" variant="secondary" onClick={declineInvite}>Dismiss & Decline</Button>
          <Button className="btn-lg" onClick={acceptInvite}>Accept</Button>
        </Col>
      </Row>
    </Alert>
  )
}

export default TeamInviteAlert
