import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const ConfirmationDialog = ({
  show, title, body, closeTxt, submitTxt, onCancel, onSubmit,
}) => (
  <Modal show={show}>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>

    {body && (
    <Modal.Body>
      <p>{body}</p>
    </Modal.Body>
    )}

    <Modal.Footer>
      <Button variant="secondary" onClick={onCancel}>{closeTxt || 'Close'}</Button>
      <Button variant="primary" onClick={onSubmit}>{submitTxt || 'OK'}</Button>
    </Modal.Footer>
  </Modal>
)

export default ConfirmationDialog
