import React, { useState } from 'react'
import { Card, Row } from 'react-bootstrap'
import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import BandwidthChart from '../BandwidthChart'
import Loader from '../../../components/Loader'

const QUERY = gql`
    query Bandwidth($f: BandwidthFilter, $g: BandwidthGrouper) {
        bandwidth(f: $f, g: $g) {
            time
            value
            deviceID
        }
    }
`

export const BandwidthCard = ({ lastNDays }) => {
  // eslint-disable-next-line no-unused-vars
  const [variables, setVariables] = useState({
    start: moment().subtract(lastNDays, 'days').startOf('day'),
    end: moment().add(1, 'day').startOf('day'),
  })

  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      f: variables,
      g: { date: true },
    },
  })

  if (loading) return <Loader />
  if (error) {
    console.error(error)
    return <Loader />
  }

  const { bandwidth } = data
  if (!bandwidth) {
    return null
  }

  return (
    <Card>
      <Card.Body>
        <Row className="mb-4">
          <h4>
            {`Last ${lastNDays} days`}
          </h4>
        </Row>
        <Row>
          <BandwidthChart bandwidth={bandwidth} lastNDays={lastNDays} />
        </Row>
      </Card.Body>
    </Card>
  )
}
