import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

const SubmitButton = ({
  isSubmitting, isDisabled, className, value, variant, onClick,
}) => (
  <Button
    type="submit"
    onClick={onClick}
    variant={variant || 'success'}
    className={className || 'button-filled'}
    disabled={isDisabled}
  >
    {isSubmitting
      ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="ms-2 sr-only">Loading...</span>
        </>
      ) : value || 'SAVE CHANGES'}
  </Button>
)

export default SubmitButton
