import React from 'react'
import Lottie from 'react-lottie-player'
import animation from './pc-earnings-animation.json'
import './PCEarningAnimation.css'

const PCEarningAnimation = () => (
  <div className="pc-earning-animation">
    <Lottie
      loop
      play
      animationData={animation}
    />
  </div>
)

export default PCEarningAnimation
