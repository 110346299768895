import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import moment from 'moment'
import { useStopwatch } from 'react-timer-hook'
import BalanceProgress from './BalanceProgress'
import UserStats from './UserStats'
import EarnAnimation from './EarnAnimation'

const BalanceCard = ({
  emails,
  payoutRequested,
  withdrawalAmount,
  last24HReferredBandwidth,
  devices,
  stats,
}) => {
  // eslint-disable-next-line no-empty-pattern
  const {} = useStopwatch({ autoStart: true })
  const {
    overallBalance,
    last24h: {
      earnings, bandwidth, bandwidthPrice, deviceMinutes,
    },
    hourlyBonus,
    lastOnlineBonusUnix,
    lastSeenOnlineUnix,
  } = stats
  let earnedBonus = 0
  if (devices && lastOnlineBonusUnix) {
    const bonusPerSecond = hourlyBonus / (60 * 60)
    const timePassed = moment.duration(moment.utc().diff(moment.unix(lastOnlineBonusUnix).utc()))
    earnedBonus = timePassed.as('seconds') * bonusPerSecond
  }
  let deviceSeconds = deviceMinutes * 60
  if (devices && lastSeenOnlineUnix) {
    const timePassed = moment.duration(moment.utc().diff(moment.unix(lastSeenOnlineUnix).utc()))
    deviceSeconds += timePassed.as('seconds')
  }
  return (
    <Card className="h-100">
      <Card.Body>
        <Row>
          <Col style={{ cursor: 'default' }}>
            <EarnAnimation />
          </Col>
          <Col>
            <Row>
              <Col>
                <Row className="mb-1">
                  <h5>Current Balance</h5>
                </Row>
                <Row className="mb-3">
                  <BalanceProgress
                    payoutRequested={payoutRequested}
                    emails={emails}
                    overallBalance={overallBalance + earnedBonus}
                    withdrawalAmount={withdrawalAmount}
                  />
                </Row>
              </Col>
            </Row>
            <Row>
              <UserStats
                last24HReferredBandwidth={last24HReferredBandwidth}
                last24Earnings={earnings}
                last24Bandwidth={bandwidth}
                last24BandwidthPrice={bandwidthPrice}
                last24DeviceSeconds={deviceSeconds}
              />
            </Row>
          </Col>
        </Row>

      </Card.Body>
    </Card>
  )
}

export default BalanceCard
