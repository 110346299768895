import React from 'react'
import {
  Button, Col, Form, Row, Alert,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './LoginForm.css'

const LoginForm = ({
  values,
  errors,
  status,
  title,
  handleSubmit,
  handleChange,
  handleBlur,
  showResendVerification,
  location,
}) => (
  <Form className="form-wrapper" onSubmit={handleSubmit}>
    <h2 className="text-center mt-4 form-title">{title}</h2>
    <h6 className="text-center mt-4">Welcome back! Please login to your account to continue.</h6>
    <Row>
      <Form.Group as={Col} md="12" sm="12">
        <Form.Label htmlFor="email">Email</Form.Label>
        <Form.Control
          type="text"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.email}
          name="email"
          required
        />
        {errors.email
          && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
      </Form.Group>
    </Row>
    <Row>
      <Form.Group as={Col} md="12" sm="12">
        <Form.Label htmlFor="password">Password</Form.Label>
        <Form.Control
          type="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.password}
          name="password"
          required
        />
        {errors.password
          && <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>}
      </Form.Group>
    </Row>
    {status && (
    <Row className="mt-2">
      <Col className="text-uppercase text-center fs-6">
        <Alert variant="warning">
          {status.message}
        </Alert>
      </Col>
    </Row>
    )}
    <Row className="mt-2">
      <Form.Group as={Col} md="12" sm="12" className="mt-20 text-center">
        <Button
          variant="success"
          type="submit"
        >
          LOGIN
        </Button>
      </Form.Group>
    </Row>
    <Row className="mt-2">
      {showResendVerification && (
      <Col md={12} sm={12} className="mt-2 text-center forgot-links text-14">
        <Link to="/auth/resend-verify-email">Cannot find verification email?</Link>
      </Col>
      )}
      <Col md={12} sm={12} className="mt-2 text-center forgot-links text-14">
        <Link to={{
          pathname: '/auth/signup-email',
          search: location.search,
        }}
        >
          Do not have an account?
        </Link>
      </Col>
      <Col md={12} sm={12} className="mt-2 text-center forgot-links text-14">
        <Link to="/auth/reset-password">Forgot password?</Link>
      </Col>
    </Row>
  </Form>
)

export default LoginForm
