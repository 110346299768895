import React from 'react'
import { Dropdown } from 'react-bootstrap'
import Gravatar from 'react-gravatar'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../context/auth'

export default ({ email, name }) => {
  const { signout } = useAuth()
  return (
    <Dropdown>
      <Dropdown.Toggle variant="light" id="dropdown-basic">
        <Gravatar
          className="rounded-left"
          size={30}
          email={email}
          default="mp"
        />
        <span className="ms-2 me-2" style={{ textTransform: 'none' }}>{name}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/account">Account</Dropdown.Item>
        <Dropdown.Item href="#" onClick={signout}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
