import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button } from 'react-bootstrap'

export default () => {
  const location = useLocation()
  return (
    <div className="d-flex">
      <Link to={{
        pathname: '/auth/signin',
        search: location.search,
      }}
      >
        <Button
          variant="link"
          className="float-right"
        >
          Login
        </Button>
      </Link>
      <Link to={{
        pathname: '/auth/signup',
        search: location.search,
      }}
      >
        <Button
          variant="outline-success"
          className="float-right"
        >
          Sign Up
        </Button>
      </Link>
    </div>
  )
}
