import React from 'react'
import {
  Container, Row,
} from 'react-bootstrap'
import ReferralOverview from './ReferralOverview'
import ReferralAnalytics from './ReferralAnalytics'

export default () => (
  <Container>
    <Row>
      <ReferralOverview />
    </Row>
    <Row className="mt-3">
      <ReferralAnalytics />
    </Row>
  </Container>
)
