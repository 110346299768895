import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Button, Col, Row } from 'react-bootstrap'
import notify from '../../components/Notify'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import { useAuth } from '../../context/auth'

const MUTATION = gql`
    mutation DeleteAccount {
        deleteAccount
    }
`

const DeleteAccount = () => {
  const { signout } = useAuth()
  const [deleteAccount, { error }] = useMutation(MUTATION)
  const [showDeleteAccModal, setShowDeleteAccModal] = useState(false)
  const handleSubmit = async () => {
    await deleteAccount()
    setShowDeleteAccModal(false)
    notify.info('Your account has been deleted')
    setTimeout(() => {
      signout()
    }, 1500)
  }

  if (error && Array.isArray(error.graphQLErrors) && error.graphQLErrors.length) {
    notify.error(error.graphQLErrors[0].message)
  }
  return (
    <>
      <ConfirmationDialog
        title="Are you sure you want to delete you account?"
        body="We will not be able to restore your account. All the progress you've made so far will be lost"
        closeTxt="Cancel"
        submitTxt="Delete"
        show={showDeleteAccModal}
        onCancel={() => setShowDeleteAccModal(false)}
        onSubmit={handleSubmit}
      />
      <Row className="mt-3">
        <Col><h5 className="p-0">Manage account</h5></Col>
        <Col>
          <Button
            onClick={() => setShowDeleteAccModal(true)}
            className="float-end"
            variant="danger"
          >
            Delete account
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default DeleteAccount
