import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useState } from 'react'
import lodash from 'lodash'
import Loader from '../../components/Loader'
import TransactionsChart from './TransactionsChart'

const QUERY = gql`
    query Transactions($f: TransactionsFilter, $s: TransactionsSorter, $g: TransactionsGrouper) {
        dailyTransactions(f: $f, s: $s, g: $g) {
            time
            balance
            amount
            transactionType
        }
    }
`

export default () => {
  const today = moment().utc().add(1, 'days').toDate()
  const monthAgo = moment().utc().subtract(1, 'months').toDate()
  // eslint-disable-next-line no-unused-vars
  const [variables, setVariables] = useState({
    start: monthAgo,
    end: today,
  })
  const {
    loading, error, data,
  } = useQuery(QUERY, {
    variables: {
      f: {
        createdAtAfter: variables.start,
        createdAtBefore: variables.end,
      },
      s: {
        createdAtDesc: false,
      },
      g: {
        time: true,
        transactionType: true,
      },
    },
  })
  if (loading || error) {
    return <Loader />
  }
  if (error) {
    console.error(error)
  }

  const { dailyTransactions: items } = data
  const points = lodash(items).groupBy('time').map((objs, key) => ({
    time: key,
    bandwidth: lodash.get(lodash.find(objs, { transactionType: 'direct' }), 'amount', 0),
    'time online': lodash.get(lodash.find(objs, { transactionType: 'bonus_online' }), 'amount', 0),
    bonus: lodash.get(lodash.find(objs, { transactionType: 'bonus' }), 'amount', 0),
    referrals: lodash.get(lodash.find(objs, { transactionType: 'referral' }), 'amount', 0),
  })).value()

  return (
    <TransactionsChart
      data={points}
      xKey="time"
    />
  )
}
