import React from 'react'
import {
  Alert, Col, Form, Row,
} from 'react-bootstrap'
import { formatMoney } from '../../../format'

const WithdrawalForm = ({
  balance, withdrawalEmail, amount, changeAmount, error,
}) => (
  <Form>
    <Row className="text-center">
      <Col>
        <span>
          Funds will be transferred to&nbsp;
          <b>{withdrawalEmail}</b>
        </span>
      </Col>
    </Row>
    <Row className="mt-2">
      <Col>
        <Form.Label htmlFor="amount">
          {`Enter amount to withdraw (max ${formatMoney(balance, 0)})`}
        </Form.Label>
        <Form.Control
          value={amount}
          max={balance}
          step={1}
          min={20}
          onChange={(e) => changeAmount(parseInt(e.target.value, 10))}
          isInvalid={false}
          pattern="\d*"
          name="name"
          type="number"
          required
        />
      </Col>
    </Row>
    <Row className="text-muted mt-4">
      <Col><span>*usually it takes up to 24h to process</span></Col>
    </Row>
    {error && (
      <Row className="mt-4">
        <Col>
          <Alert variant="danger">{error}</Alert>
        </Col>
      </Row>
    )}
  </Form>
)

export default WithdrawalForm
