import React from 'react'
import { Pagination } from 'react-bootstrap'

export default ({ pages, currentPage, onPageChange }) => {
  if (!pages || pages <= 1) {
    return null
  }

  // Some params can be tweaked later
  const sideLinksCount = 1
  const siblingLinksCount = 1

  let elements
  // Simple case
  if (pages <= sideLinksCount * 2 + siblingLinksCount * 2 + 3) {
    elements = [...Array(pages).keys()].map((x) => x + 1)

    // Things are getting complicated here
  } else {
    let midLinks
    const startLinks = [...Array(sideLinksCount).keys()].map((x) => x + 1).filter((x) => x <= pages)
    const endLinks = [...Array(sideLinksCount).keys()].map((x) => pages - x).reverse()

    // Current page is in the start of the list
    if (currentPage <= sideLinksCount + siblingLinksCount + 1) {
      midLinks = [...Array(siblingLinksCount * 2 + 1).keys()]
        .map((x) => x + startLinks.length + 2)
      // Current page is in the end of the list
    } else if (currentPage > pages - (sideLinksCount + siblingLinksCount + 1)) {
      midLinks = [...Array(siblingLinksCount * 2 + 1).keys()]
        .map((x) => pages - (x + sideLinksCount + 1))
        .reverse()
      // Current page is somewhere in between
    } else {
      midLinks = [...Array(siblingLinksCount * 2 + 1).keys()]
        .map((x) => currentPage - siblingLinksCount + x)
        .filter((x) => x <= pages)
    }
    const startSpacer = midLinks[0] === sideLinksCount + 2
      ? sideLinksCount + 1
      : false
    const endSpacer = midLinks[midLinks.length - 1] === pages - (sideLinksCount + 1)
      ? pages - (sideLinksCount)
      : false
    elements = startLinks.concat([startSpacer])
    elements = elements.concat(midLinks)
    elements = elements.concat([endSpacer])
    elements = elements.concat(endLinks)
  }

  return (
    <Pagination>
      <Pagination.Prev
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage > 1 ? currentPage - 1 : 1)}
      />
      <>
        {elements.map((value, idx) => (
          value
            ? (
              <Pagination.Item
                id={idx}
                key={value}
                onClick={() => onPageChange(value)}
                active={value === currentPage}
              >
                {value}
              </Pagination.Item>
            )
            : (<Pagination.Ellipsis key={value} />)
        ))}
      </>
      {
        pages > 1
        && (
          <Pagination.Next
            onClick={() => onPageChange(currentPage !== pages ? currentPage + 1 : pages)}
            disabled={currentPage === pages}
          />
        )
      }
    </Pagination>
  )
}
