import React from 'react'
import {
  Card,
} from 'react-bootstrap'
import classnames from 'classnames'
import './DataCard.css'

export default ({
  className, children,
}) => (
  <Card className={classnames('data-card', className)}>
    <Card.Body>
      <div className="m-4">
        {children}
      </div>
    </Card.Body>
  </Card>
)
