import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { Col, Row } from 'react-bootstrap'
import get from 'lodash/get'
import Header from '../../components/Header'
import RefCode from './RefCode'
import Loader from '../../components/Loader'
import DataCard from './components/DataCard'
import ReferredBy from './ReferredBy'
import ChangePassword from './ChangePassword'
import UserName from './UserName'
import UserEmails from './UserEmails'
import AlterEmail from './AlterEmail'
import DeleteAccount from './DeleteAccount'

export const QUERY = gql`
    query CurrentUser {
        currentUser {
            id
            email
            name
            handle
            referredBy
            teamMember {
                id
                userID
                teamID
                team {
                    id
                    name
                }
            }
            details {
                id
                teamReferral
            }
        }
    }
`

const MUTATION = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
            name
        }
    }
`

export default () => {
  const { loading, error, data } = useQuery(QUERY)
  if (loading || error) {
    return <Loader />
  }

  const { currentUser } = data
  const {
    handle, referredBy, name, teamMember, details: { teamReferral },
  } = currentUser

  return (
    <Row>
      <Header title="Account" />
      <Col lg={10} md={10} xs={12}>
        <DataCard className="rounded">
          <UserEmails
            refetchQuery={QUERY}
          />
        </DataCard>
      </Col>
      <Col lg={10} md={10} xs={12}>
        <DataCard className="mb-4">
          <AlterEmail />
        </DataCard>
      </Col>
      <Col lg={10} md={10} xs={12}>
        <DataCard className="rounded mb-4">
          <UserName
            initialValue={name}
            refetchQuery={QUERY}
            mutation={MUTATION}
          />
        </DataCard>
      </Col>
      <Col lg={10} md={10} xs={12}>
        <DataCard>
          <RefCode
            refCode={handle}
            teamRef={teamReferral}
            team={get(teamMember, 'team')}
            refetchQuery={QUERY}
            mutation={MUTATION}
          />
        </DataCard>
      </Col>
      {referredBy && (
        <Col lg={10} md={10} xs={12}>
          <DataCard className="rounded">
            <ReferredBy handle={referredBy} />
          </DataCard>
        </Col>
      )}
      <Col lg={10} md={10} xs={12}>
        <DataCard className="rounded mt-4">
          <ChangePassword />
        </DataCard>
      </Col>
      <Col lg={10} md={12} xs={12}>
        <DataCard className="rounded mt-4">
          <DeleteAccount />
        </DataCard>
      </Col>
    </Row>
  )
}
