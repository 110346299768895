import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { TrashFill } from 'react-bootstrap-icons'
import { gql, useMutation } from '@apollo/client'
import ConfirmationDialog from '../../../components/ConfirmationDialog'
import notify from '../../../components/Notify'
import { convertBytesToMiB } from '../../../format'

const MUTATION = gql`
    mutation DeleteDevice($id: String!) {
        deleteDevice(id: $id)
    }
`

const DeleteDeviceAction = ({
  className, id, identifier, bandwidthSold, refetchQueries,
}) => {
  const [deleteDevice, { error }] = useMutation(MUTATION, { variables: { id }, refetchQueries })
  const [deleteDeviceVisible, setDeleteDeviceVisible] = useState(false)
  const handleSubmit = async () => {
    await deleteDevice()
    setDeleteDeviceVisible(false)
    notify.info('Device has been deleted')
  }

  if (error && Array.isArray(error.graphQLErrors) && error.graphQLErrors.length) {
    notify.error(error.graphQLErrors[0].message)
  }

  const body = `${convertBytesToMiB(bandwidthSold, 0).toFixed(1)} MiB made through this record will be lost`

  return (
    <span className={className}>
      <ConfirmationDialog
        title={`Are you sure you want to remove "${identifier}"?`}
        body={bandwidthSold > 0 ? body : 'Remove only if have not plans for future usage'}
        closeTxt="Cancel"
        submitTxt="Delete"
        show={deleteDeviceVisible}
        onCancel={() => setDeleteDeviceVisible(false)}
        onSubmit={handleSubmit}
      />
      <OverlayTrigger overlay={<Tooltip id="tooltip">Click to remove device</Tooltip>}>
        <TrashFill onClick={() => setDeleteDeviceVisible(true)} />
      </OverlayTrigger>
    </span>
  )
}

export default DeleteDeviceAction
