import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Redirect } from 'react-router-dom'
import ResetPasswordForm from './components/ResetPasswordForm'
import { useAuth } from '../../context/auth'
import Layout from './components/Layout'
import TopBar from '../layout/Topbar'
import AuthLinks from '../layout/components/AuthLinks'

const ResetPassword = () => {
  const { user, resetPassword } = useAuth()
  if (user) {
    return <Redirect to="/dashboard" />
  }

  const handleSubmit = async ({ email }, { setSubmitting, setStatus }) => {
    try {
      setSubmitting(true)
      await resetPassword({ email })
      setStatus({ msg: 'Email sent.', ok: true })
    } catch (e) {
      const message = e.response ? e.response.data.message : 'Invalid email.'
      setStatus({ msg: message, ok: false })
      setSubmitting(false)
    }
  }

  return (
    <Layout>
      <Container>
        <TopBar>
          <AuthLinks />
        </TopBar>
        <Row className="mt-5">
          <Col md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }}>
            <Formik
              onSubmit={handleSubmit}
              initialValues={{ email: '' }}
              validationSchema={Yup.object({
                email: Yup.string().email().required('Email is required'),
              })}
            >
              {(props) => <ResetPasswordForm title="Reset password" {...props} />}
            </Formik>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ResetPassword
