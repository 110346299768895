import {
  Redirect, Route, Switch, useLocation,
} from 'react-router-dom'
import React from 'react'
import WindowsDownload from './WindowsDownload'

export default () => {
  const location = useLocation()
  return (
    <Switch>
      <Route exact path="/download/install-income.exe" component={WindowsDownload} />
      <Route>
        <Redirect to={{
          pathname: '/download/install-income.exe',
          search: location.search,
        }}
        />
      </Route>
    </Switch>
  )
}
