export const getSelectedItem = (items, location) => {
  let selectedItem = null
  const selectItem = (arr) => {
    arr.forEach((item) => {
      if (item.link === location.pathname) {
        selectedItem = item
        return
      }

      if (item.children) {
        selectItem(item.children)
      }
    })
  }
  selectItem(items)
  return selectedItem
}
