import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Formik } from 'formik'
import Cookies from 'js-cookie'
import * as Yup from 'yup'
import queryString from 'query-string'
import { Redirect, useLocation } from 'react-router-dom'
import zxcvbn from 'zxcvbn'
import SignupForm from './components/SignupForm'
import { useAuth } from '../../context/auth'
import Layout from './components/Layout'
import TopBar from '../layout/Topbar'
import Header from '../../components/Header'
import { gqlErrorOr } from '../../utils'
import AuthLinks from '../layout/components/AuthLinks'

const VerifyEmail = ({ email }) => (
  <div className="mt-5">
    <h2 className="text-center mb-4 main-color">Success, please verify your email</h2>
    <h6 className="text-center main-color">
      By following a link we sent to&nbsp;
      <b>{email}</b>
    </h6>
  </div>
)

const SignupEmail = () => {
  const location = useLocation()
  const [emailRegistered, setEmailRegistered] = useState(false)
  const [signedUp, setSignedUp] = useState(false)
  const { user, signupEmail } = useAuth()
  if (user) {
    return <Redirect to="/dashboard" />
  }

  const { dvc } = queryString.parse(location.search)
  const refCode = Cookies.get('refCode') || ''
  const isTeamInvite = (!!refCode && !!Cookies.get('teamInvite')) || false
  const handleSubmit = async ({
    name, email, password, referralCode, teamInvite,
  }, { setSubmitting, setStatus }) => {
    setSubmitting(true)
    await signupEmail({
      name,
      email,
      password,
      referralCode,
      device: dvc,
      teamInvite,
      onError(e) {
        const message = gqlErrorOr(e, 'Invalid email or password.')
        if (message === 'email already registered') {
          setEmailRegistered(true)
        }
        setStatus({ message })
        setSubmitting(false)
      },
      onSuccess() {
        setSignedUp(email)
      },
    })
  }

  return (
    <Layout>
      <Container>
        <Header title="Sign up" />
        <TopBar>
          <AuthLinks />
        </TopBar>
        <Row className="mt-5">
          <Col md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }}>
            {!signedUp && (
            <Formik
              onSubmit={handleSubmit}
              initialValues={{
                email: '', name: '', password: '', referralCode: refCode, teamInvite: isTeamInvite,
              }}
              validationSchema={Yup.object({
                email: Yup.string().email().required('Email is required'),
                name: Yup.string().min(3).required('Name is required'),
                password: Yup.string()
                  .required('Password is required')
                  .min(8, 'At least 8 symbols are required').test(
                    'strength',
                    'Password is too weak',
                    (value, schema) => {
                      if (!value) {
                        return true
                      }

                      const r = zxcvbn(value, [schema.parent.name, schema.parent.email])
                      return r.score >= 2
                    },
                  ),
              })}
            >
              {(props) => (
                <SignupForm
                  emailRegistered={emailRegistered}
                  hideRefField={!!refCode}
                  location={location}
                  title="Create account"
                  {...props}
                />
              )}
            </Formik>
            )}
            {signedUp && <VerifyEmail email={signedUp} />}
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default SignupEmail
