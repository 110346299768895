import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import Header from '../../components/Header'
import TopBar from '../layout/Topbar'
import Layout from './components/Layout'
import { useAuth } from '../../context/auth'
import Loader from '../../components/Loader'
import AuthLinks from '../layout/components/AuthLinks'

export default ({ match: { params: { token } } }) => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [error, setError] = useState(null)
  const { signinToken, signout } = useAuth()
  useEffect(() => {
    signout()
    signinToken({
      tkn: token,
      onError: () => {
        setError('token expired')
      },
      onSuccess: () => {
        setLoggedIn(true)
      },
    })
  }, [token])
  if (loggedIn) {
    return <Redirect to="/dashboard" />
  }

  if (error) {
    return <Redirect to="/auth/signin" />
  }

  return (
    <Layout>
      <Container>
        <Header title="Sign in" />
        <TopBar>
          <AuthLinks />
        </TopBar>
        <Row className="mt-5">
          <Col md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }}>
            <h5 className="text-center blue-color mb-2">Logging you in..</h5>
            <Loader />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
