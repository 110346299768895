import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import SubmitButton from '../../../components/SubmitButton'
import './AlterEmailForm.css'

export default ({
  values, handleChange, handleBlur, errors, handleSubmit, isSubmitting, isValid, dirty,
}) => (
  <Form className="p-0" onSubmit={handleSubmit}>
    <Row className="mb-2">
      <Col>
        <h5 className="p-0">Alternate email</h5>
      </Col>
      <Col>
        <SubmitButton
          variant="link"
          type="submit"
          className="float-end btn-blue"
          isDisabled={isSubmitting || !isValid || !dirty}
          isSubmitting={isSubmitting}
          value="Add new email"
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <Form.Control
          type="text"
          className="p-3"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.email}
          placeholder="example@example.com"
          name="email"
          required
        />
        {(errors.email) && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
      </Col>
    </Row>
  </Form>
)
