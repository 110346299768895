import React, { useState } from 'react'
import {
  Button, Container, Modal,
} from 'react-bootstrap'
import { gql, useMutation } from '@apollo/client'
import WithdrawalForm from './WithdrawalForm'
import notify from '../../../components/Notify'
import { gqlErrorOr } from '../../../utils'

const MUTATION = gql`
    mutation RequestPayout($amount: Int64!, $teamID: Int64) {
        requestPayout(amount: $amount, teamID: $teamID)
    }
`

const WithdrawalRequestDialog = ({
  show, onClose, withdrawalEmail, refetchQueries, balance, withdrawalAmount, teamID,
}) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [amount, setAmount] = useState(withdrawalAmount)
  const [error, setError] = useState(null)
  const [requestPayout] = useMutation(MUTATION)

  const handleSubmit = async () => {
    setSubmitting(true)
    try {
      await requestPayout({ variables: { amount, teamID }, refetchQueries })
      notify.info('your request has been created')
    } catch (e) {
      setError(gqlErrorOr(e, 'could not request payout'))
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header className="pb-30 pt-30">
        <Modal.Title as="h3"><span>Withdrawal Request</span></Modal.Title>
      </Modal.Header>
      <Modal.Body className="switch-plan-body pt-20">
        <Container>
          <WithdrawalForm
            withdrawalEmail={withdrawalEmail}
            balance={balance}
            amount={amount}
            changeAmount={setAmount}
            isSubmitting={isSubmitting}
            error={error}
          />
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onClose}
          disabled={isSubmitting}
        >
          Not now
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          Request
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default WithdrawalRequestDialog
