import React, { useState } from 'react'
import {
  Row, Col, Alert, FormCheck,
} from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import * as Yup from 'yup'
import './components/DataCard.css'
import { Link } from 'react-router-dom'
import notify from '../../components/Notify'
import HandleForm from './components/HandleForm'
import Label from '../../components/Label'

export default ({
  refCode, teamRef, mutation, refetchQuery, team,
}) => {
  const [checkpointActive, setCheckpointActive] = useState(false)
  const [updateUser, { error }] = useMutation(mutation, {
    refetchQueries: [{ query: refetchQuery }],
  })

  const handleSubmit = async ({ handle }, { setSubmitting }) => {
    setSubmitting(true)
    try {
      await updateUser({ variables: { input: { handle } }, refetchQueries: [{ query: refetchQuery }] })
      notify.info('referral code has been updated')
    } finally {
      setSubmitting(false)
    }
  }

  const handleTeamRefUpdate = async ({ teamReferral }) => {
    setCheckpointActive(true)
    try {
      await updateUser({ variables: { input: { teamReferral } }, refetchQueries: [{ query: refetchQuery }] })
      notify.info(teamReferral ? 'referral code now attached to the team' : 'no team attached to invite')
    } finally {
      setCheckpointActive(false)
    }
  }

  let statusError = null
  if (error && Array.isArray(error.graphQLErrors) && error.graphQLErrors.length) {
    statusError = error.graphQLErrors[0].message
  }

  return (
    <>
      <Row className="mt-4">
        <Col>
          <h5 className="p-0">Referral URL</h5>
        </Col>
      </Row>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ handle: (refCode || '').toLowerCase() }}
        validationSchema={Yup.object({
          handle: Yup.string().matches(/^[a-zA-Z0-9/-]*$/, 'Must be alphanumeric').min(1).max(15)
            .required('Code is required'),
        })}
      >
        {(props) => (
          <HandleForm
            {...props}
            statusError={statusError}
            team={team}
          />
        )}
      </Formik>
      <Row className="mt-3">
        {statusError && <Alert className="p-2" variant="danger">{statusError}</Alert>}
      </Row>
      <Row className="data-value mt-3">
        <Col>
          <Label value={`${window.location.origin}/${teamRef ? 't' : 'r'}/${refCode}`} hasCopy />
        </Col>
      </Row>
      {team && (
        <Row>
          <Col md={6} lg={6} xs={10} className="ms-1 mt-1 text-muted d-flex">
            <FormCheck
              type="checkbox"
              id="team-link"
              label="Ask referee to join"
              checked={teamRef}
              value={teamRef}
              onClick={({ target: { checked } }) => handleTeamRefUpdate({ teamReferral: checked })}
              disabled={checkpointActive}
            />
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="plain ms-1"
              to={`/teams/${team.id}`}
            >
              {team.name}
            </Link>
          </Col>
        </Row>
      )}
    </>
  )
}
