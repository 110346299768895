import React from 'react'
import { Table } from 'react-bootstrap'
import DeviceRow from './DeviceRow'

const NoDevices = () => (
  <tr>
    <td colSpan="5">
      <p className="text-center">No devices registered.</p>
    </td>
  </tr>
)

const Devices = ({ devices, refetchQueries }) => (
  <Table striped borderless hover>
    <thead>
      <tr>
        <th>Name</th>
        <th>Bandwidth Sold</th>
        <th>Last seen</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      {devices ? devices.map((item) => (
        <DeviceRow
          refetchQueries={refetchQueries}
          device={item}
          key={item.id}
        />
      )) : <NoDevices />}
    </tbody>
  </Table>
)

export default Devices
