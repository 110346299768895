import React from 'react'
import moment from 'moment'

const TransactionRow = ({
  item: {
    createdAt, amount, balance, message,
  },
}) => (
  <tr>
    <td>{moment(createdAt).format('dddd, MMMM Do YYYY')}</td>
    <td>{`${amount > 0 ? '+' : ''}${+(amount * 1000).toFixed(2)} CR`}</td>
    <td>{`${+(balance * 1000).toFixed(2)} CR`}</td>
    <td>{message}</td>
  </tr>
)
export default TransactionRow
