import React from 'react'
import {
  Alert,
  Row,
} from 'react-bootstrap'
import { gql, useMutation } from '@apollo/client'
import { Formik } from 'formik'
import * as Yup from 'yup'
import AlterEmailForm from './components/AlterEmailForm'
import notify from '../../components/Notify'
import { EMAILS_QUERY } from './components/Email'
import { useAuth } from '../../context/auth'
import { gqlErrorOr } from '../../utils'

const MUTATION = gql`
    mutation AddEmail($email: String!) {
        addEmail(email: $email)
    }
`

export default () => {
  const { refresh } = useAuth()
  const [addEmail, { error }] = useMutation(MUTATION, {
    refetchQueries: [{ query: EMAILS_QUERY }],
  })

  const handleSubmit = async ({ email }, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    try {
      await addEmail({ variables: { email } })
      notify.info('New email has been added')
      resetForm()
      await refresh()
    } finally {
      setSubmitting(false)
    }
  }

  const statusError = gqlErrorOr(error, null)

  return (
    <>
      <Row className="mt-4">
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ email: '' }}
          validationSchema={Yup.object({
            email: Yup.string().email().required('Email is required'),
          })}
        >
          {(props) => (
            <AlterEmailForm {...props} />
          )}
        </Formik>
      </Row>
      <Row className="mt-3">
        {statusError && <Alert className="p-2" variant="danger">{statusError}</Alert>}
      </Row>
    </>
  )
}
