import React from 'react'
import './ReferredBy.css'

export default ({ handle }) => (
  <div className="d-flex align-items-center">
    <h5>Referred by</h5>
&nbsp;
    <span className="ms-1 info-box p-2 blue text-lowercase">{handle}</span>
  </div>
)
