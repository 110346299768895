import React from 'react'
import { gql, useQuery } from '@apollo/client'
import {
  Card, Col, Container, Row, Table, OverlayTrigger, Tooltip,
} from 'react-bootstrap'
import { FileEarmarkArrowDown } from 'react-bootstrap-icons'
import moment from 'moment'
import { useAuth } from '../../context/auth'
import DateRangePicker from '../../components/DateRangePicker'
import TransactionRow from './TransactionRow'
import Loader from '../../components/Loader'
import PaginationWrapper from '../../components/PaginationWrapper'
import { downloadFile } from '../../utils'

const QUERY = gql`
    query Transactions($start: Time!, $end: Time!, $limit: Int64!, $offset: Int64!) {
        transactions(start: $start, end: $end, limit: $limit, offset: $offset) {
            items {
                id
                createdAt
                amount
                balance
                message
                transactionType
            }
            total
        }
    }
`

export default () => {
  const today = moment().utc().add(1, 'days').toDate()
  const monthAgo = moment().utc().subtract(1, 'months').toDate()

  const { token: { token } } = useAuth()
  const [dateRange, setDateRange] = React.useState({
    startDate: monthAgo,
    endDate: today,
  })

  const [variables, setVariables] = React.useState({
    offset: 0,
    limit: 20,
    total: 1,
  })

  const {
    loading, error, data,
  } = useQuery(QUERY, {
    variables: {
      start: dateRange.startDate,
      end: dateRange.endDate,
      limit: variables.limit,
      offset: variables.offset,
    },
    refetchQueries: [{ query: QUERY }],
  })

  const updateTransactions = (newDateRange) => {
    setDateRange(newDateRange)
  }

  const downloadCSV = async () => {
    const start = moment(dateRange.startDate).startOf('day')
    const end = moment(dateRange.endDate).endOf('day')

    const jsonBody = { start, end }
    const endpoint = '/reports/transactions'
    const fileName = `earn_spider_com_ref_transactions__${start.format('L')}-${end.format('L')}.csv`
    await downloadFile(endpoint, jsonBody, fileName, token)
  }

  if (loading || error) {
    return <Loader />
  }
  if (error) {
    console.error(error)
    return <Loader />
  }

  const { transactions } = data
  const { items, total } = transactions
  const pageCount = Math.ceil(total / variables.limit)
  const currentPage = Math.ceil(variables.offset / variables.limit) + 1
  const handlePageChange = (nextPage) => {
    setVariables({
      ...variables,
      offset: (nextPage - 1) * variables.limit,
      total,
    })
  }

  return (
    <Card>
      <Card.Body>
        <Container>
          <DateRangePicker initialValues={dateRange} onRangeSelected={updateTransactions} />
          <Row className="mt-4">
            <h5>
              <span>Your account transactions history</span>
              &nbsp;
              <span className="float-end">
                <OverlayTrigger overlay={<Tooltip id="tooltip">Download data as CSV file</Tooltip>}>
                  <FileEarmarkArrowDown onClick={downloadCSV} cursor="pointer" />
                </OverlayTrigger>
              </span>
            </h5>
          </Row>
          <Row>
            <Table striped borderless hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Balance</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                {items && items.map((x) => <TransactionRow item={x} key={x.id} />)}
              </tbody>
            </Table>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <PaginationWrapper
                currentPage={currentPage}
                pages={pageCount}
                onPageChange={handlePageChange}
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  )
}
