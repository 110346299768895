import React from 'react'
import {
  Row, Col, Alert,
} from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import * as Yup from 'yup'
import './components/DataCard.css'
import notify from '../../components/Notify'
import NameForm from './components/NameForm'

export default ({
  initialValue, mutation, refetchQuery,
}) => {
  const [updateUser, { error }] = useMutation(mutation, {
    refetchQueries: [{ query: refetchQuery }],
  })

  const handleSubmit = async ({ name }, { setSubmitting }) => {
    setSubmitting(true)
    try {
      await updateUser({ variables: { input: { name } } })
      notify.info('your name has been updated')
    } finally {
      setSubmitting(false)
    }
  }

  let statusError = null
  if (error && Array.isArray(error.graphQLErrors) && error.graphQLErrors.length) {
    statusError = error.graphQLErrors[0].message
  }

  return (
    <>
      <Row className="mt-4">
        <Col><h5 className="p-0">Full Name</h5></Col>
      </Row>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ name: initialValue }}
        validationSchema={Yup.object({
          name: Yup.string().min(3).required('Name is required'),
        })}
      >
        {(props) => (
          <NameForm
            {...props}
            statusError={statusError}
          />
        )}
      </Formik>
      <Row className="mt-3">
        {statusError && <Alert className="p-2" variant="danger">{statusError}</Alert>}
      </Row>
    </>
  )
}
