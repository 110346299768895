import React from 'react'
import {
  Alert, Button, Col, Form, Row,
} from 'react-bootstrap'

export default ({
  title, values, errors, statusError, handleSubmit, handleChange, handleBlur,
}) => (
  <Form className="form-wrapper" onSubmit={handleSubmit}>
    <h2 className="text-center mb-40 form-title">{title}</h2>
    <Row className="mb-2">
      <Form.Group className="text-center" as={Col} md="12" sm="12">
        <Form.Label htmlFor="email">Send it again to</Form.Label>
        <Form.Control
          type="text"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.email}
          name="email"
          required
        />
        {errors.email
        && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
      </Form.Group>
    </Row>
    {statusError && <Row className="mt-2"><Col><Alert variant="danger">{statusError}</Alert></Col></Row>}
    <Row className="mt-2">
      <Form.Group as={Col} md="12" sm="12" className="mt-20 text-center">
        <Button
          variant="success"
          type="submit"
        >
          RESEND
        </Button>
      </Form.Group>
    </Row>
  </Form>
)
