import React from 'react'
import Lottie from 'react-lottie-player'
import animation from './balance-animation.json'
import './EarnAnimation.css'

const EarnAnimation = () => (
  <div className="earn-animation">
    <Lottie
      loop
      play
      animationData={animation}
    />
  </div>
)

export default EarnAnimation
