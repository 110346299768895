import { gql } from '@apollo/client'

export const USER_FIELDS = gql`
    fragment UserFields on User {
        id
        handle
        details {
            id
            referralBalance
            last24HReferredBandwidth
            referredUsers
            teamReferral
        }
        requested {
            id
            payoutStatus
        }
    }
`
