import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { useAuth } from '../../context/auth'
import Loader from '../../components/Loader'
import notify from '../../components/Notify'
import { gqlErrorOr } from '../../utils'

const MUTATION = gql`
    mutation AssociateDevice($device: String!) {
        associateDevice(device: $device)
    }
`

export const AssociatedUserDevice = ({ match: { params: { device } } }) => {
  const [associated, setAssociated] = useState(false)
  const [associateDevice, { error }] = useMutation(MUTATION)
  const { user, signout } = useAuth()
  useEffect(() => {
    if (!user) {
      return null
    }

    associateDevice({ variables: { device } })
      .then(() => {
        setAssociated(true)
      })
  }, [])
  if (!user) {
    return (
      <Redirect to={{
        pathname: '/auth/signin',
        search: `?dvc=${device}`,
      }}
      />
    )
  }

  if (error) {
    notify.error(gqlErrorOr(error, 'Could not associate device, please login to proceed'))
    signout()
    return (
      <Redirect to={{
        pathname: '/auth/signin',
        search: `?dvc=${device}`,
      }}
      />
    )
  }

  if (!associated) {
    return (
      <Loader />
    )
  }

  return <Redirect to="/dashboard" />
}

export const AssociateDeviceRoute = ({ match: { params: { device } } }) => {
  const { user, signout } = useAuth()
  if (user) {
    return (
      <Redirect to={{
        pathname: `/associate/${device}`,
      }}
      />
    )
  }

  signout()
  return (
    <Redirect to={{
      pathname: '/auth/signin',
      search: `?dvc=${device}`,
    }}
    />
  )
}
