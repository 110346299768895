import React from 'react'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts'
import moment from 'moment'

export default ({ data, xKey, yKey }) => (
  <ResponsiveContainer
    height={300}
  >
    <BarChart
      height={300}
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey={xKey}
        tickFormatter={(tick) => moment(tick).format('DD-MMM')}
      />
      <YAxis
        tickFormatter={(tick) => `${tick} MiB`}
      />
      <Tooltip
        labelFormatter={(label) => moment(label).format('DD-MMM')}
        formatter={(value) => `${new Intl.NumberFormat('en').format(value)} MiB`}
      />
      <Bar
        type="monotone"
        dataKey={yKey}
        stroke="#99a1c4"
        fill="#99a1c4"
      />
    </BarChart>
  </ResponsiveContainer>
)
