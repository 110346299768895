import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Redirect, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import LoginForm from './components/LoginForm'
import { useAuth } from '../../context/auth'
import Layout from './components/Layout'
import TopBar from '../layout/Topbar'
import Header from '../../components/Header'
import { gqlErrorOr } from '../../utils'
import AuthLinks from '../layout/components/AuthLinks'

const SigninEmail = () => {
  const location = useLocation()
  const { user, signinEmail } = useAuth()
  const [showResendVerification, setShowResendVerification] = useState(false)
  if (user) {
    return <Redirect to="/dashboard" />
  }

  const { dvc } = queryString.parse(location.search)
  const handleSubmit = async ({ email, password }, { setSubmitting, setStatus }) => {
    setSubmitting(true)
    await signinEmail({
      email,
      password,
      device: dvc,
      onError(e) {
        const message = gqlErrorOr(e, 'Invalid email or password.')
        const notVerified = message === 'no verified email found'
        setShowResendVerification(notVerified)
        setStatus({ message })
        setSubmitting(false)
      },
    })
  }

  return (
    <Layout>
      <Container>
        <Header title="Sign in" />
        <TopBar>
          <AuthLinks />
        </TopBar>
        <Row className="mt-5">
          <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
            <Formik
              onSubmit={handleSubmit}
              initialValues={{ email: '', password: '' }}
              validationSchema={Yup.object({
                email: Yup.string().email().required('Email is required'),
                password: Yup.string().required('Password is required').min(8, 'At least 8 symbols are required'),
              })}
            >
              {(props) => (
                <LoginForm
                  title="Log in"
                  showResendVerification={showResendVerification}
                  location={location}
                  {...props}
                />
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default SigninEmail
