import React from 'react'
import {
  Col, Row,
} from 'react-bootstrap'
import { gql, useQuery } from '@apollo/client'
import get from 'lodash/get'
import Loader from '../../components/Loader'
import Header from '../../components/Header'
import TeamInviteAlert from './components/TeamInviteAlert'
import BalanceCard from './components/BalanceCard'
import ReferAFriendCard from './components/ReferAFriendCard'
import { BandwidthCard } from './components/BandwidthCard'
import { DevicesCard } from './Devices/DevicesCard'
import { StarterLinks } from './components/StarterLinks'
import { CallToDownloadCard } from './components/CallToDownloadCard'
import { USER_FIELDS } from '../../graphql_queries'

const QUERY = gql`
    ${USER_FIELDS}
    query CurrentUser {
        withdrawalAmount
        emails {
            id
            email
            main
            verified
            isPaypal
        }
        currentUser {
            ...UserFields
        }
        stats {
            overallBalance
            hourlyBonus
            last24h {
                earnings
                bandwidth
                deviceMinutes
            }
            lastOnlineBonusUnix
            lastSeenOnlineUnix
        }
        teamInvite {
            id
            inviter {
                id
                name
            }
            team {
                id
                name
                bio
            }
        }
        devices {
            id
            identifier
            updatedAt
            details {
              deviceId
              bandwidthSold
            }
            app {
              id
              serviceVersion
            }
        }
    }
`

export default () => {
  const lastNDays = 21
  const {
    loading,
    error,
    data,
  } = useQuery(QUERY, {
    pollInterval: 7000,
  })
  if (error) {
    console.log(error)
  }
  if (loading || error) {
    return <Loader />
  }

  const {
    emails,
    withdrawalAmount,
    stats,
    currentUser: {
      handle,
      requested,
      details: {
        last24HReferredBandwidth,
        teamReferral,
      },
    },
    devices,
    teamInvite,
  } = data
  const hasDevices = get(devices, 'length', 0) > 0
  return (
    <>
      <Header title="Dashboard" />
      {teamInvite && (
        <Row className="p-1">
          <TeamInviteAlert
            teamInvite={teamInvite}
            refetchQueries={[{ query: QUERY }]}
          />
        </Row>
      )}
      <Row>
        {hasDevices && (
          <Col className="p-1" lg={8} md={8} xs={12}>
            <BalanceCard
              emails={emails}
              stats={stats}
              payoutRequested={requested}
              withdrawalAmount={withdrawalAmount}
              last24HReferredBandwidth={last24HReferredBandwidth}
              devices={devices}
            />
          </Col>
        )}
        {hasDevices && (
          <Col className="p-1" lg={4} md={4} xs={12}>
            <ReferAFriendCard handle={handle} teamReferral={teamReferral} />
          </Col>
        )}
        {hasDevices && (
          <>
            <Col className="p-1" lg={12} md={12} xs={12}>
              <BandwidthCard lastNDays={lastNDays} />
            </Col>
            <Col className="p-1" lg={12} md={12} xs={12}>
              <DevicesCard devices={devices} query={QUERY} />
            </Col>
          </>
        )}
        {!hasDevices && (
          <>
            <Col className="p-1" lg={7} md={5} xs={12}>
              <CallToDownloadCard />
            </Col>
            <Col className="p-1" lg={5} md={5} xs={12}>
              <StarterLinks />
            </Col>
          </>
        )}
      </Row>
    </>
  )
}
