import React from 'react'
import moment from 'moment'
import { formatCredit } from '../../format'

const RefAnalyticRow = ({ item }) => {
  const daysActive = moment(item.createdAt).fromNow(true)
  const signUpDate = moment(item.createdAt).calendar('dd/mm/yyyy')
  const earnings = formatCredit(item.earnings * 1000)
  const myCommission = formatCredit(item.myCommission * 1000)

  return (
    <tr>
      <td>{item.name}</td>
      <td>{item.refLevelOneBased}</td>
      <td>{earnings}</td>
      <td>{myCommission}</td>
      <td>{item.deviceCount}</td>
      <td>{signUpDate}</td>
      <td>{daysActive}</td>
    </tr>
  )
}
export default RefAnalyticRow
