import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Clipboard } from 'react-bootstrap-icons'
import copy from 'copy-to-clipboard'
import classnames from 'classnames'
import notify from './Notify'
import './Label.css'

export default ({ value, hasCopy, className }) => {
  const copyToClipboard = (text) => () => {
    notify.info('Ready to paste')
    copy(text)
  }

  return (
    <span className={classnames('p-3 rounded-2 label info', className)}>
      {value}
      {hasCopy && (
        <OverlayTrigger overlay={<Tooltip id="tooltip">Click to copy</Tooltip>}>
          <Clipboard onClick={copyToClipboard(value)} size={24} />
        </OverlayTrigger>
      )}
    </span>
  )
}
