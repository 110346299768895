import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { PencilFill } from 'react-bootstrap-icons'
import EditDeviceDialog from './EditDeviceDialog'

const EditDeviceAction = ({
  className, id, identifier, refetchQueries,
}) => {
  const [editDeviceVisible, setEditDeviceVisible] = useState(false)
  const handleClose = () => setEditDeviceVisible(false)

  return (
    <span className={className}>
      <EditDeviceDialog
        refetchQueries={refetchQueries}
        show={editDeviceVisible}
        handleClose={handleClose}
        identifier={identifier}
        id={id}
      />
      <OverlayTrigger overlay={<Tooltip id="tooltip">Click to rename</Tooltip>}>
        <PencilFill onClick={() => setEditDeviceVisible(true)} />
      </OverlayTrigger>
    </span>
  )
}

export default EditDeviceAction
