import React from 'react'
import {
  Alert, Col, Form, Row,
} from 'react-bootstrap'
import SubmitButton from '../../../components/SubmitButton'

export default ({
  values, errors, handleSubmit, handleChange, handleBlur, statusError, isSubmitting, isValid,
}) => (
  <Form onSubmit={handleSubmit}>
    <Row>
      <Form.Group as={Col} md={12} sm={12}>
        <Form.Label htmlFor="currentPassword">Password</Form.Label>
        <Form.Control
          type="password"
          value={values.currentPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.currentPassword}
          name="currentPassword"
          required
        />
        {errors.currentPassword
          && <Form.Control.Feedback type="invalid">{errors.currentPassword}</Form.Control.Feedback>}
      </Form.Group>
    </Row>
    <Row>
      <Form.Group as={Col} md={12} sm={12}>
        <Form.Label htmlFor="newPassword">New Password</Form.Label>
        <Form.Control
          value={values.newPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.newPassword}
          name="newPassword"
          type="password"
        />
        {errors.newPassword
          && <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>}
      </Form.Group>
    </Row>
    <Row>
      <Form.Group as={Col} md={12} sm={12}>
        <Form.Label htmlFor="newPasswordConfirm">Confirm New Password</Form.Label>
        <Form.Control
          value={values.newPasswordConfirm}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.newPasswordConfirm}
          name="newPasswordConfirm"
          type="password"
        />
        {errors.newPasswordConfirm
          && <Form.Control.Feedback type="invalid">{errors.newPasswordConfirm}</Form.Control.Feedback>}
      </Form.Group>
    </Row>
    {statusError && <Row className="mt-2"><Col><Alert variant="danger">{statusError}</Alert></Col></Row>}
    <Row className="mt-2">
      <Form.Group as={Col} md="12" sm="12" className="mt-3 text-end">
        <SubmitButton
          isDisabled={isSubmitting || !isValid}
          isSubmitting={isSubmitting}
          value="Change password"
        />
      </Form.Group>
    </Row>
  </Form>
)
