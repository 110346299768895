import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Nav, Navbar, NavLink } from 'react-bootstrap'
import classnames from 'classnames'
import { Lightbulb, ArrowDownCircle } from 'react-bootstrap-icons'
import logoSpider from '../../assets/images/logo_spider.svg'
import logoSpiderCollapse from '../../assets/images/logo_spider_collapse.svg'
import { getSelectedItem } from './utils'
import './Sidebar.css'

const NavLinkItem = ({ item }) => (
  <>
    {item.icon}
    <span className="ms-3">{item.name}</span>
  </>
)

const CollapseNavItem = ({ item, selected, setSelected }) => {
  // eslint-disable-next-line max-len
  const isActive = () => selected.prev !== item
    && (item === selected.current || item.children.find((x) => x === selected.current))

  const isOpen = item === selected.current || item.children.find((x) => x === selected.current)

  const handleClick = () => {
    // eslint-disable-next-line no-unused-expressions
    isActive()
      ? setSelected({ prev: item, current: null })
      : setSelected({ prev: selected.current, current: item })
  }

  return (
    <div>
      <Nav.Link
        data-toggle=""
        className={classnames({ active: isActive() })}
        onClick={handleClick}
      >
        <NavLinkItem item={item} />
      </Nav.Link>
      <div className={classnames('sub-menu collapse', { show: isOpen })}>
        <Nav as="ul" className="flex-column">
          {item.children.map((i) => (
            <NavItem
              selected={selected}
              setSelected={setSelected}
              key={`sub-${i.id}`}
              item={i}
            />
          ))}
        </Nav>
      </div>
    </div>
  )
}

const PlainNavItem = ({ item, selected, setSelected }) => (
  <Link
    className={classnames('nav-link', { active: item === selected.current })}
    to={item.link}
    onClick={() => setSelected({ prev: selected.current, current: item })}
  >
    <NavLinkItem item={item} />
  </Link>
)

const NavItem = ({ item, selected, setSelected }) => (
  <Nav.Item as="li">
    {
        item.children
          ? <CollapseNavItem selected={selected} setSelected={setSelected} item={item} />
          : <PlainNavItem selected={selected} setSelected={setSelected} item={item} />
      }
  </Nav.Item>
)

const Sidebar = ({ items, location, history }) => {
  const selectedItem = getSelectedItem(items, location)
  const [selected, setSelected] = useState({ prev: null, current: selectedItem })
  useEffect(() => {
    history.listen((locState) => {
      const newSelection = getSelectedItem(items, locState)
      if (newSelection
        && selectedItem
        && selected.current
        && ((newSelection.id !== selected.current.id) || !selected.prev)) {
        setSelected({ prev: selected.current, current: newSelection })
      }
    })
  }, [])

  return (
    <div className="sidebar-container sidebar-container-default">
      <nav className="sidebar">
        <div className="sidebar-sticky d-flex flex-column">
          <Navbar.Brand as="a">
            <img alt="Spider LLC" className="logo-default" src={logoSpider} />
            <img alt="Spider LLC" className="logo-collapse" src={logoSpiderCollapse} />
          </Navbar.Brand>
          <ul className="flex-column nav">
            {items.map((i) => <NavItem key={i.id} item={i} selected={selected} setSelected={setSelected} />)}
          </ul>
          <NavLink
            className="mt-auto"
            href="https://www.spider.com/income/tips"
            target="_blank"
            rel="noopener noreferrer"
          >
            <NavLinkItem item={{ icon: <Lightbulb size={24} />, name: 'Power Tips' }} />
          </NavLink>
          <NavLink
            href="/download"
            target="_blank"
            rel="noopener noreferrer"
          >
            <NavLinkItem item={{ icon: <ArrowDownCircle size={24} />, name: 'Download' }} />
          </NavLink>
        </div>
      </nav>
    </div>
  )
}

export default withRouter(Sidebar)
