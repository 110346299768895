import download from 'downloadjs'
import { getAPIUrl } from './config'

export const gqlErrorOr = (error, defaultErr) => {
  if (error && Array.isArray(error.graphQLErrors) && error.graphQLErrors.length) {
    return error.graphQLErrors[0].message
  }

  return defaultErr
}

export const downloadFile = async (endPoint, jsonBody, fileName, token) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(jsonBody),
  }
  const endpoint = `${getAPIUrl()}${endPoint}`
  const response = await fetch(endpoint, requestOptions)
  const csvData = await response.text()

  download(csvData, fileName, 'text/csv')
}
