import React from 'react'
import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom'
import {
  Window, People, CreditCard,
} from 'react-bootstrap-icons'
import { ApolloProvider } from '@apollo/client'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { useAuth } from '../context/auth'
import { clientMain } from '../graphql'
import Layout from './layout'
import Dashboard from './dashboard'
import Account from './account'
import Referrals from './referrals'
import NestedRoute from './layout/NestedRoute'
import Transactions from './transactions'

import './index.css'
import 'react-toastify/dist/ReactToastify.css'
import RefAnalyticRow from './referrals/RefAnalyticRow'
import { AssociatedUserDevice } from './auth/AssociateDeviceRoute'

momentDurationFormatSetup(moment)

const sidebarItems = [
  {
    id: 10,
    name: 'Dashboard',
    link: '/dashboard',
    exact: true,
    icon: <Window size={24} />,
    component: Dashboard,
  },
  {
    id: 30,
    name: 'Referrals',
    link: '/referrals',
    exact: true,
    icon: <People size={24} />,
    component: Referrals,
  },
  {
    id: 50,
    name: 'Transactions',
    link: '/transactions',
    exact: true,
    icon: <CreditCard size={24} />,
    component: Transactions,
  },
]

export default () => {
  const { user, token, signout } = useAuth()
  if (!user) {
    return <Redirect to="/auth/signin" />
  }

  return (
    <ApolloProvider client={clientMain({ token: token.token, onError: signout })}>
      <BrowserRouter>
        <Layout sidebarItems={sidebarItems}>
          <Switch>
            {sidebarItems.map(NestedRoute)}
            <Route exact path="/referrals/analytics" component={RefAnalyticRow} />
            <Route path="/associate/:device" component={AssociatedUserDevice} />
            <Route path="/account" component={Account} />
            <Redirect to="/dashboard" />
          </Switch>
        </Layout>
      </BrowserRouter>
    </ApolloProvider>
  )
}
