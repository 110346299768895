import React, { useState } from 'react'
import {
  Alert, Button, Col, Form, Row,
} from 'react-bootstrap'
import { Gift } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import './SignupForm.css'

const SignupForm = ({
  title,
  values,
  setValues,
  initialValues,
  errors,
  status,
  handleSubmit,
  handleChange,
  handleBlur,
  hideRefField,
  location,
  emailRegistered,
}) => {
  const [showRefField, setShowRefField] = useState(!hideRefField)

  const switchFormState = () => {
    setShowRefField(!showRefField)
  }

  return (
    <Form className="form-wrapper" onSubmit={handleSubmit}>
      <h2 className="text-center mb-40 form-title">{title}</h2>
      <Row className="mb-2">
        <Form.Group as={Col} md="12" sm="12">
          <Form.Label htmlFor="email">Name</Form.Label>
          <Form.Control
            type="text"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.name}
            name="name"
            required
          />
          {errors.name
            && <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>}
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} md="12" sm="12">
          <Form.Label htmlFor="email">Email</Form.Label>
          <Form.Control
            type="text"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.email}
            name="email"
            required
          />
          {errors.email
            && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} md="12" sm="12">
          <Form.Label htmlFor="password">Password</Form.Label>
          <Form.Control
            type="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.password}
            name="password"
            required
          />
          {errors.password
            && <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>}
        </Form.Group>
      </Row>
      {showRefField && (
        <Row className="mb-2">
          <Form.Group as={Col} md="12" sm="12">
            <Form.Label htmlFor="referralCode">Referral Code</Form.Label>
            <Form.Control
              type="text"
              value={values.referralCode}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.referralCode}
              name="referralCode"
              autoComplete="off"
            />
            {errors.referralCode
              && <Form.Control.Feedback type="invalid">{errors.referralCode}</Form.Control.Feedback>}
          </Form.Group>
        </Row>
      )}
      <Row className="mb-2">
        <Form.Group as={Col} md="12" sm="12">
          {!showRefField && (
            <Alert variant="success" style={{ marginBottom: '4px' }}>
              <Gift size="25" style={{ paddingBottom: '4px' }} />
              &nbsp;Thanks for using a referral link, You will be given a $5 bonus when signing up.
            </Alert>
          )}
          <Row className="justify-content-md-center">
            {hideRefField && (
              <Col className="text-center">
                <Button
                  variant="link"
                  onClick={() => {
                    switchFormState()
                    if (hideRefField) {
                      setValues({ ...values, referralCode: initialValues.referralCode }, false)
                    }
                  }}
                  style={{ textTransform: 'none' }}
                >
                  {!showRefField ? 'You can also enter the code manually' : 'Stick back with your referral link'}
                </Button>
              </Col>
            )}
          </Row>
        </Form.Group>
      </Row>
      <Row className="mb-2 d-none">
        <Form.Group as={Col} md="12" sm="12">
          <Form.Control
            type="checkbox"
            value={values.teamInvite}
            onChange={handleChange}
            onBlur={handleBlur}
            name="teamInvite"
          />
        </Form.Group>
      </Row>
      {status && <Row className="mt-2"><Col><Alert variant="danger">{status.message}</Alert></Col></Row>}
      <Row className="mt-2">
        <Form.Group as={Col} md="12" sm="12" className="mt-20 text-center">
          <Button
            variant="success"
            type="submit"
          >
            Signup
          </Button>
        </Form.Group>
      </Row>
      <Row className="mt-2">
        <Form.Group as={Col} md={12} sm={12} className="mt-20 text-center forgot-links text-14">
          <Link to={{
            pathname: '/auth/signin',
            search: location.search,
          }}
          >
            I have an account
          </Link>
        </Form.Group>
        {emailRegistered && (
          <Col md={12} sm={12} className="mt-2 text-center forgot-links text-14">
            <Link to="/auth/resend-verify-email">Cannot find verification email?</Link>
          </Col>
        )}
      </Row>
    </Form>
  )
}

export default SignupForm
