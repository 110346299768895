import React from 'react'
import { Modal } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { gql, useMutation } from '@apollo/client'
import EditDeviceForm from './EditDeviceForm'
import notify from '../../../components/Notify'

const MUTATION = gql`
    mutation UpdateDevice($id: String!, $identifier: String!) {
        updateDevice(id: $id, identifier: $identifier)
    }
`

export default ({
  show, handleClose, id, identifier, refetchQueries,
}) => {
  const [editDevice] = useMutation(MUTATION, {
    refetchQueries,
  })
  const initialValues = { name: identifier }

  const handleSubmit = (
    { name },
    {
      setSubmitting, setStatus, resetForm, setFieldError,
    },
  ) => {
    editDevice(
      {
        variables: { id, identifier: name },
        refetchQueries,
      },
    ).then(() => {
      setSubmitting(false)
      resetForm()
      handleClose()
      notify.info('Successfully updated')
    }).catch((err) => {
      console.error(err)
      if (Array.isArray(err.graphQLErrors)
        && err.graphQLErrors.length) {
        if (initialValues[err.graphQLErrors[0].path[0]] !== undefined) {
          setFieldError(err.graphQLErrors[0].path[0], err.graphQLErrors[0].message)
        } else {
          setStatus(err.graphQLErrors[0].message)
        }
      } else {
        setStatus('Oops.. something went wrong')
      }

      setSubmitting(false)
    })
  }

  const handleReset = (_, { setValues }) => {
    setValues(initialValues)
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header className="pb-30 pt-30">
        <Modal.Title as="h3"><span>Edit device</span></Modal.Title>
      </Modal.Header>
      <Modal.Body className="switch-plan-body pt-20">
        <Formik
          onSubmit={handleSubmit}
          onReset={handleReset}
          initialValues={initialValues}
          validationSchema={Yup.object({
            name: Yup.string()
              .min(6, 'At least 6 symbols are required')
              .required('Name is required')
              .matches(/^([a-zA-Z0-9/-]+)$/, 'Must be alphanumeric'),
          })}
        >
          {(props) => (
            <EditDeviceForm onCancel={handleClose} {...props} />
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}
