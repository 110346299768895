import React from 'react'
import {
  Alert, Col, Form, Row,
} from 'react-bootstrap'
import SubmitButton from '../../../components/SubmitButton'

export default ({
  values, errors, handleSubmit, onCancel, handleChange, handleBlur, status, isSubmitting, isValid,
}) => (
  <Form onSubmit={handleSubmit}>
    <div className="form-row-container">
      <Row className="mt-2">
        <Col md={12} sm={12}>
          <Form.Label htmlFor="name">Name</Form.Label>
          <Form.Control
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.name}
            name="name"
            type="text"
            required
          />
          {errors.name
          && <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>}
        </Col>
      </Row>
      {status && <Alert variant="danger">{status}</Alert>}
      <Row className="mt-2">
        <Col xs={12}>
          <SubmitButton
            className="w-100 h-100"
            isDisabled={isSubmitting || !isValid}
            isSubmitting={isSubmitting}
            value="Submit"
          />
        </Col>
        <Col xs={12} className="text-center">
          <button
            type="button"
            disabled={isSubmitting}
            className="btn-blank"
            onClick={onCancel}
          >
            NOT NOW
          </button>
        </Col>
      </Row>
    </div>
  </Form>
)
