import React from 'react'
import {
  Col, Form, Row,
} from 'react-bootstrap'
import SubmitButton from '../../../components/SubmitButton'

export default ({
  values, handleChange, handleBlur, errors, handleSubmit, isSubmitting, isValid,
}) => (
  <Form id="handle-form" className="mt-2" onSubmit={handleSubmit}>
    <Row className="p-0">
      <Col className="mt-2" md={8} lg={8} xs={12}>
        <Form.Control
          type="text"
          className="p-3 label border-0 text-lowercase"
          value={values.handle}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.handle}
          name="handle"
          required
        />
        {(errors.handle) && <Form.Control.Feedback type="invalid">{errors.handle}</Form.Control.Feedback>}
      </Col>
      <Col className="mt-2" lg={4} md={4} xs={12}>
        <SubmitButton
          className="w-100 h-100"
          isDisabled={isSubmitting || !isValid}
          isSubmitting={isSubmitting}
          value="UPDATE"
        />
      </Col>
    </Row>
  </Form>
)
