import React from 'react'
import { Col, Row } from 'react-bootstrap'
import moment from 'moment'
import {
  convertBytesToMiB, formatCredit, formatMoney,
} from '../../../format'

export default ({
  last24Bandwidth, last24Earnings, last24DeviceSeconds, last24HReferredBandwidth,
}) => {
  if (
    last24Bandwidth === 0
    && last24Earnings === 0
    && last24DeviceSeconds === 0
    && last24HReferredBandwidth === 0
  ) {
    return null
  }

  return (
    <Row>
      <Col xs={12} lg={12} md={12} className="mb-1">
        <h6>Last 24 hours</h6>
      </Col>
      <Col xs={12} lg={12} md={12}>
        <ul style={{ fontSize: '14px' }}>
          {last24Bandwidth > 0 && (
            <li>
              Direct bandwidth:&nbsp;
              {`${convertBytesToMiB(last24Bandwidth).toFixed(1)} MiB`}
            </li>
          )}
          {last24DeviceSeconds > 0 && (
            <li>
              Time online:&nbsp;
              {`${moment.duration(last24DeviceSeconds, 'seconds').format('hh:mm:ss')}`}
            </li>
          ) }
          {last24HReferredBandwidth > 0 && (
            <li>
              Referral Traffic:&nbsp;
              {`${convertBytesToMiB(last24HReferredBandwidth).toFixed(1)} MiB`}
            </li>
          )}
          <li>
            Earned in the last 24h:&nbsp;
            {`${formatCredit(last24Earnings * 1000)} CR (${formatMoney(last24Earnings, 3)})`}
          </li>
        </ul>
      </Col>
    </Row>
  )
}
