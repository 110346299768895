import React from 'react'
import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom'
import { useAuth } from './context/auth'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/dashboard.css'
import Auth from './pages/auth'
import Download from './pages/download'
import Main from './pages'
import SetReferrerCookie from './pages/auth/SetReferrerCookie'
import SetReferrerTeamCookie from './pages/auth/SetReferrerTeamCookie'
import VerifyEmail from './pages/auth/VerifyEmail'
import ResendVerificationEmail from './pages/auth/ResendVerificationEmail'
import SigninToken from './pages/auth/SigninToken'
import { AssociateDeviceRoute } from './pages/auth/AssociateDeviceRoute'

function App() {
  const { user } = useAuth()
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/auth/signin/:token" component={SigninToken} />
        <Route exact path="/r/:refCode" component={SetReferrerCookie} />
        <Route exact path="/t/:refCode" component={SetReferrerTeamCookie} />
        <Route exact path="/d/:device" component={AssociateDeviceRoute} />
        <Route exact path="/auth/verify-email/:token" component={VerifyEmail} />
        <Route exact path="/auth/resend-verify-email/" component={ResendVerificationEmail} />
        <Route path="/signup">
          <Redirect to="/auth/signup" />
        </Route>
        <Route path="/signin">
          <Redirect to="/auth/signin" />
        </Route>
        <Route path="/auth" component={Auth} />
        <Route path="/download" component={Download} />
        {!user && <Redirect to="/auth" />}
        <Route component={() => <Main />} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
