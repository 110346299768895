import React from 'react'
import { Row } from 'react-bootstrap'
import { useQuery } from '@apollo/client'
import Loader from '../../components/Loader'
import Email, { EMAILS_QUERY } from './components/Email'

export default () => {
  const { data, loading, qError } = useQuery(EMAILS_QUERY)
  if (loading || qError) {
    return <Loader />
  }

  const { emails } = data
  return (
    <>
      <Row className="mt-4">
        <h5 className="p-0">Email</h5>
      </Row>
      <Row>
        {emails.map((x) => (
          <Email
            key={x.email}
            email={x.email}
            main={x.main}
            verified={x.verified}
            isPaypal={x.isPaypal}
          />
        ))}
      </Row>
    </>
  )
}
