import {
  Card, Col, Row,
} from 'react-bootstrap'
import React from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share'
import Label from '../../../components/Label'

const ReferAFriendCard = ({ handle, teamReferral }) => {
  const title = 'Effortlessly Earn passive income'
  const url = `${window.location.origin}/${teamReferral ? 't' : 'r'}/${handle}`
  return (
    <Card className="h-100">
      <Card.Body>
        <Row className="mb-2">
          <h5>Refer a friend</h5>
        </Row>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <span className="text-muted">
              Earn 25% of your friend&apos;s lifetime earnings when they use your code to signup.&nbsp;
              Friend of Friends earn you 10% on their lifetime earnings.
            </span>
          </Col>
          <Col xs={12} lg={12} md={12} className="mt-2">
            <Label
              className="small"
              value={url}
              hasCopy
            />
          </Col>
        </Row>
        <Row className="mt-4 justify-content-center">
          <Col>
            <FacebookShareButton
              className="m-1"
              quote={title}
              url={url}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton
              className="m-1"
              title={title}
              url={url}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton
              className="m-1"
              title={title}
              separator=":: "
              url={url}
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <TelegramShareButton
              className="m-1"
              title={title}
              url={url}
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>
            <RedditShareButton
              className="m-1"
              title={title}
              url={url}
            >
              <RedditIcon size={32} round />
            </RedditShareButton>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default ReferAFriendCard
