import React from 'react'
import {
  Alert, Button, Col, Form, Row,
} from 'react-bootstrap'
import './LoginForm.css'

const SetPasswordForm = ({
  values, errors, status, title, handleSubmit, handleChange, handleBlur,
}) => (
  <Form className="form-wrapper" onSubmit={handleSubmit}>
    <h2 className="text-center mt-4 form-title">{title}</h2>
    <Row>
      <Form.Group as={Col} md="12" sm="12">
        <Form.Label htmlFor="password">Your new password</Form.Label>
        <Form.Control
          type="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.password}
          name="password"
          required
        />
        {errors.password
          && <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>}
      </Form.Group>
    </Row>
    <Row>
      <Form.Group as={Col} md="12" sm="12">
        <Form.Label htmlFor="confirm_password">Confirm new password</Form.Label>
        <Form.Control
          type="password"
          value={values.confirm_password}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.confirm_password}
          name="confirm_password"
          required
        />
        {errors.confirm_password
          && <Form.Control.Feedback type="invalid">{errors.confirm_password}</Form.Control.Feedback>}
      </Form.Group>
    </Row>
    {status && (
      <Row className="mt-2">
        <Col>
          <Alert variant={status.ok ? 'success' : 'danger'}>{status.msg}</Alert>
        </Col>
      </Row>
    )}
    <Row className="mt-2">
      <Form.Group as={Col} md="12" sm="12" className="mt-20 text-center">
        <Button
          variant="success"
          type="submit"
        >
          Set new password
        </Button>
      </Form.Group>
    </Row>
  </Form>
)

export default SetPasswordForm
