import React, { useState } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Gear } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import WithdrawalRequestDialog from './WithdrawalRequestDialog'

const NoPayPalWithdrawalButton = ({ title }) => (
  <>
    <OverlayTrigger
      overlay={(
        <Tooltip id="tooltip">
          <span>You have no verified PayPal email</span>
        </Tooltip>
      )}
    >
      <span>
        <Button size="sm" className="w-75" variant="secondary" disabled>
          {title}
        </Button>
      </span>
    </OverlayTrigger>
    <OverlayTrigger
      overlay={(
        <Tooltip id="tooltip-gear">
          <span>Configure PayPal email</span>
        </Tooltip>
      )}
    >
      <Link className="ms-2" to="/account"><Gear size={24} color="#51c431" /></Link>
    </OverlayTrigger>
  </>
)

const payoutClasses = {
  rejected: 'danger',
}

const PayoutInfo = ({ tooltip, status }) => (
  <OverlayTrigger
    overlay={(
      <Tooltip id="tooltip">
        <span>{tooltip}</span>
      </Tooltip>
    )}
  >
    <span>
      <Button
        size="sm"
        className="w-100"
        variant={get(payoutClasses, status, 'primary')}
        disabled
      >
        {`Withdrawal ${status}`}
      </Button>
    </span>
  </OverlayTrigger>
)

const WithdrawalRequestButton = ({
  refetchQueries, emails, balance, withdrawalAmount, payoutRequested, teamID,
}) => {
  const [dialogVisible, setDialogVisible] = useState(false)
  const title = 'Request Withdrawal'
  const paypalEmail = emails.find((e) => e.verified && e.isPaypal)
  if (!paypalEmail) {
    return <NoPayPalWithdrawalButton title={title} />
  }

  if (payoutRequested) {
    return (
      <PayoutInfo
        title={title}
        status={payoutRequested.payoutStatus}
        tooltip="Payout request is being reviewed"
      />
    )
  }

  return (
    <>
      <WithdrawalRequestDialog
        show={dialogVisible}
        balance={balance}
        withdrawalAmount={withdrawalAmount}
        withdrawalEmail={paypalEmail.email}
        onClose={() => setDialogVisible(false)}
        teamID={teamID}
        refetchQueries={refetchQueries}
      />
      <Button
        size="sm"
        className="w-100"
        variant="success"
        onClick={() => setDialogVisible(true)}
      >
        {title}
      </Button>
    </>
  )
}

export default WithdrawalRequestButton
