import React from 'react'
import { Card, Row } from 'react-bootstrap'
import moment from 'moment'
import Devices from './index'

export const DevicesCard = ({ devices = [], query }) => {
  const activeDevices = devices.filter((d) => moment(d.updatedAt).isAfter(moment().subtract(14, 'days')))
  const inactiveDevices = devices.length - activeDevices.length
  return (
    <Card className="h-100">
      <Card.Body className="m-3">
        <Row className="mb-2">
          <h5>
            <span className="me-1">Devices Bandwidth</span>
            <a
              href={`${window.location.origin}/download/install-income.exe`}
              target="_blank"
              className="text-decoration-none"
              rel="noopener noreferrer"
            >
              (latest version)
            </a>
          </h5>
        </Row>
        {inactiveDevices > 0 && (
        <Row>
          <h6>
            <span className="text-muted">
              {`${inactiveDevices} devices have not been active in the last 14 days`}
            </span>
          </h6>
        </Row>
        )}
        <Row>
          <Devices devices={activeDevices} refetchQueries={[{ query }]} />
        </Row>
      </Card.Body>
    </Card>
  )
}
