import React from 'react'
import { gql, useQuery } from '@apollo/client'
import {
  Card, Container, Row, Table, OverlayTrigger, Tooltip,
} from 'react-bootstrap'

import { Download } from 'react-bootstrap-icons'
import moment from 'moment'
import { useAuth } from '../../context/auth'
import DateRangePicker from '../../components/DateRangePicker'
import Header from '../../components/Header'
import RefAnalyticRow from './RefAnalyticRow'
import Loader from '../../components/Loader'
import { downloadFile } from '../../utils'

const QUERY = gql`
    query refAnalytics($start: Time!, $end: Time!) {
        refAnalytics(start: $start, end: $end) {
            name
            refLevelOneBased
            deviceCount
            earnings
            myCommission
            createdAt
        }
    }
`

export default () => {
  const today = moment().toDate()
  const monthAgo = moment().subtract(30, 'days').toDate()

  const { token: { token } } = useAuth()
  const [dateRange, setDateRange] = React.useState({
    startDate: monthAgo,
    endDate: today,
  })

  const {
    loading, error, data,
  } = useQuery(QUERY, {
    variables: {
      start: moment(dateRange.startDate).startOf('day').toDate(),
      end: moment(dateRange.endDate).endOf('day').toDate(),
    },
  })

  const downloadCSV = async () => {
    const start = moment(dateRange.startDate).startOf('day')
    const end = moment(dateRange.endDate).endOf('day')

    const jsonBody = { start, end }
    const endpoint = '/reports/refs'
    const fileName = `earn_spider_com_ref_analytics__${start.format('L')}-${end.format('L')}.csv`
    await downloadFile(endpoint, jsonBody, fileName, token)
  }

  const UpdateDateRange = (newDateRange) => {
    setDateRange(newDateRange)
  }

  if (loading) {
    return <Loader />
  }
  if (error) {
    console.error(error)
    return <Loader />
  }

  const { refAnalytics } = data
  return (
    <Card>
      <Header title="Transactions" />
      <Card.Body>
        <Container>
          <DateRangePicker initialValues={dateRange} onRangeSelected={UpdateDateRange} />
          <Row className="mt-4">
            <h5>
              Your referral income details&nbsp;
              <OverlayTrigger overlay={<Tooltip id="tooltip">Download data as CSV file</Tooltip>}>
                <Download onClick={downloadCSV} cursor="pointer" />
              </OverlayTrigger>
            </h5>
          </Row>
          <Row>
            <Table striped borderless hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Level</th>
                  <th>Earnings</th>
                  <th>My commission</th>
                  <th>Devices</th>
                  <th>Sign up</th>
                  <th>Days active</th>
                </tr>
              </thead>
              <tbody>
                {refAnalytics && refAnalytics.map((x) => <RefAnalyticRow item={x} key={x.createdAt} />)}
              </tbody>
            </Table>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  )
}
