import React from 'react'
import {
  Button, Card, Col, Row,
} from 'react-bootstrap'
import {
  FileArrowDown, Window, FilePerson,
} from 'react-bootstrap-icons'
import PCEarningAnimation from './PCEarningAnimation'
import DownloadImage from '../../../assets/images/download-icon.svg'

export const CallToDownloadCard = () => (
  <Card className="h-100">
    <Card.Body>
      <Row className="d-flex align-items-center">
        <Col className="mt-3">
          <Row>
            <Col className="text-center"><h4>3 steps to Earn™</h4></Col>
          </Row>
          <Row className="mt-3 ms-2 d-flex flex-row">
            <Col className="d-flex align-items-center">
              <FileArrowDown color="#51c431" />
              <h6 className="ms-2 mb-0">Download the App</h6>
            </Col>
          </Row>
          <Row className="mt-1 ms-3">
            <Col>
              <span>Install the app on your Windows computer desktop.</span>
            </Col>
          </Row>
          <Row className="mt-3 ms-2">
            <Col className="d-flex align-items-center">
              <Window color="#51c431" />
              <h6 className="ms-2 mb-0">Run Earn</h6>
            </Col>
          </Row>
          <Row className="mt-1 ms-3">
            <Col>
              <span>You can watch passively as we report income to you!</span>
            </Col>
          </Row>
          <Row className="mt-3 ms-2">
            <Col className="d-flex align-items-center">
              <FilePerson color="#51c431" />
              <h6 className="ms-2 mb-0">Tell Friends</h6>
            </Col>
          </Row>
          <Row className="mt-1 ms-3">
            <Col>
              <span>We will reward you with 25% of their lifetime earnings! </span>
            </Col>
          </Row>
        </Col>
        <Col>
          <PCEarningAnimation />
        </Col>
      </Row>
      <Row className="w-100 bottom-0 position-absolute mb-2">
        <Col className="w-100">
          <Button
            variant="link"
            className="btn-link-2 text-center w-100"
            href="/download"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Download Earn App" className="pb-1 pt-1" src={DownloadImage} />
            <span className="ms-2">Download for Windows</span>
          </Button>
        </Col>
      </Row>
    </Card.Body>
  </Card>
)
