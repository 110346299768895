import React from 'react'
import {
  Col, Container, Row,
} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { gql, useQuery } from '@apollo/client'
import get from 'lodash/get'
import Sidebar from './Sidebar'
import TopBar from './Topbar'
import { getSelectedItem } from './utils'
import './index.css'
import UserInfo from './components/UserInfo'
import Loader from '../../components/Loader'

export const LAYOUT_QUERY = gql`
    query CurrentUser {
        currentUser {
            id
            name
            email
            teamMember {
                id
                teamID
                userID
                team {
                    id
                    name
                }
            }
        }
    }
`

export default withRouter(({ children, sidebarItems, location }) => {
  const { data, loading, error } = useQuery(LAYOUT_QUERY)
  if (loading || error) {
    return <Loader />
  }

  const { currentUser: { name, email } } = data
  const team = get(data, 'currentUser.teamMember.team')
  const selectedItem = getSelectedItem(sidebarItems, location)

  return (
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col className="sidebar" lg={2} md={2} xs={2}>
          <Sidebar items={sidebarItems} />
        </Col>
        <Col className="content" lg={10} md={10} xs={10}>
          <TopBar>
            <UserInfo
              email={email}
              name={name}
              team={team}
            />
          </TopBar>
          <Container className="mt-4 mb-4">
            <Row>
              <h2>{selectedItem && selectedItem.name}</h2>
            </Row>
            <Row>
              <Container>
                {children}
              </Container>
            </Row>
          </Container>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  )
})
