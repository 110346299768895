import React from 'react'
import { Row } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { gql, useMutation } from '@apollo/client'
import ChangePasswordForm from './components/ChangePasswordForm'
import notify from '../../components/Notify'

const MUTATION = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
        changePassword(input: $input)
    }
`

export default () => {
  const [changePassword, { error }] = useMutation(MUTATION)
  const handleSubmit = async ({ currentPassword, newPassword }, { resetForm, setSubmitting }) => {
    setSubmitting(true)
    try {
      await changePassword({ variables: { input: { currentPassword, newPassword } } })
      resetForm()
      notify.info('your password has been changed')
    } finally {
      setSubmitting(false)
    }
  }

  let statusError = null
  if (error && Array.isArray(error.graphQLErrors) && error.graphQLErrors.length) {
    statusError = error.graphQLErrors[0].message
  }

  return (
    <>
      <Row className="mt-4">
        <h5 className="p-0">Change password</h5>
      </Row>
      <Row className="mt-3">
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            currentPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
          }}
          validationSchema={Yup.object({
            currentPassword: Yup.string().required('Password is required'),
            newPassword: Yup.string()
              .required('New password is required')
              .min(8, 'At least 8 symbols are required')
              .notOneOf([Yup.ref('password'), null], 'Please provide new password'),
            newPasswordConfirm: Yup.string()
              .required('Password confirm is required')
              .min(8, 'At least 8 symbols are required')
              .oneOf([Yup.ref('newPassword'), null], 'Passwords don\'t match'),
          })}
        >
          {(props) => <ChangePasswordForm statusError={statusError} {...props} />}
        </Formik>
      </Row>
    </>
  )
}
