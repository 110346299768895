import React, { useState } from 'react'
import {
  PatchCheck, PatchCheckFill, EnvelopeOpen, Envelope, CurrencyDollar, Trash,
} from 'react-bootstrap-icons'
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { gql, useMutation } from '@apollo/client'
import notify from '../../../components/Notify'
import { useAuth } from '../../../context/auth'
import { gqlErrorOr } from '../../../utils'

export const EMAILS_QUERY = gql`
    query UserEmails {
        emails {
            id
            email
            main
            verified
            isPaypal
        }
    }
`

const MAKE_PRIMARY = gql`
    mutation SetPrimaryEmail($email: String!) {
        makeEmailPrimary: makeEmailPrimary(email: $email)
    }
`

const MAKE_PAYPAL = gql`
    mutation SetPaypalEmail($email: String!) {
        makeEmailPaypal: makeEmailPaypal(email: $email)
    }
`

const DELETE_EMAIL = gql`
    mutation DeleteEmail($email: String!) {
        deleteEmail: deleteEmail(email: $email)
    }
`

const EmailAction = ({
  active, iconOn, iconOff, tooltipOn, tooltipOff, id,
}) => (
  <OverlayTrigger overlay={(
    <Tooltip id={id}>
      {active ? tooltipOn : tooltipOff}
    </Tooltip>
  )}
  >
    {active ? iconOn : iconOff}
  </OverlayTrigger>
)

export default ({
  email, main, verified, isPaypal,
}) => {
  const [loading, setLoading] = useState(false)
  const { sendVerifyEmail, refresh } = useAuth()
  const [setPrimary] = useMutation(MAKE_PRIMARY, {
    refetchQueries: [{ query: EMAILS_QUERY }],
  })
  const [setPaypal] = useMutation(MAKE_PAYPAL, {
    refetchQueries: [{ query: EMAILS_QUERY }],
  })
  const [deleteEmail] = useMutation(DELETE_EMAIL, {
    refetchQueries: [{ query: EMAILS_QUERY }],
  })

  const onSetPrimary = async () => {
    setLoading(true)
    try {
      await setPrimary({ variables: { email } })
      notify.info('Your primary email has been updated')
      await refresh()
    } catch (e) {
      const msg = gqlErrorOr(e, null)
      if (msg) {
        notify.error(msg)
      }
    } finally {
      setLoading(false)
    }
  }

  const onSetPaypal = async () => {
    setLoading(true)
    try {
      await setPaypal({ variables: { email } })
      notify.info('Your PayPal email has been updated')
      await refresh()
    } catch (e) {
      const msg = gqlErrorOr(e, null)
      if (msg) {
        notify.error(msg)
      }
    } finally {
      setLoading(false)
    }
  }

  const onSendVerifyEmail = async () => {
    setLoading(true)
    try {
      await sendVerifyEmail({ email })
      await refresh()
    } catch (e) {
      if (e && Array.isArray(e.graphQLErrors) && e.graphQLErrors.length) {
        notify.error(e.graphQLErrors[0].message)
      }
    } finally {
      notify.info('Verification email has been sent')
      setLoading(false)
    }
  }

  const onDeleteEmail = async () => {
    setLoading(true)
    try {
      await deleteEmail({ variables: { email } })
      notify.info('An email has been deleted')
      await refresh()
    } catch (e) {
      const msg = gqlErrorOr(e, null)
      if (msg) {
        notify.error(msg)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="p-3 rounded-2 label info m-1">
      {email}
      {loading && (
        <span className="float-end">
          <Spinner animation="border" size="sm" />
        </span>
      )}
      {!loading && (
        <span className="float-end">
          <EmailAction
            id="primary-email"
            active={main}
            iconOn={<PatchCheckFill className="ms-2" size={24} fill="green" />}
            iconOff={<PatchCheck size={24} className="ms-2" fill="grey" onClick={onSetPrimary} />}
            tooltipOn="Primary email"
            tooltipOff="Click to set as your primary email"
          />
          <EmailAction
            id="verify-email"
            active={verified}
            iconOn={<EnvelopeOpen size={24} className="ms-2" fill="green" />}
            iconOff={<Envelope size={24} className="ms-2" fill="grey" onClick={onSendVerifyEmail} />}
            tooltipOn="This email is verified"
            tooltipOff="Click to resend verification email"
          />
          <EmailAction
            id="paypal-email"
            active={isPaypal}
            iconOn={<CurrencyDollar size={24} className="ms-2" fill="green" />}
            iconOff={<CurrencyDollar size={24} className="ms-2" fill="grey" onClick={onSetPaypal} />}
            tooltipOn="This is your PayPal email"
            tooltipOff="Click to set as PayPal email"
          />
          {!main && !isPaypal && (
            <OverlayTrigger overlay={<Tooltip id="tooltip">Click to delete an email</Tooltip>}>
              <Trash className="ms-2" fill="red" onClick={onDeleteEmail} size={24} />
            </OverlayTrigger>
          )}
        </span>
      )}
    </div>
  )
}
