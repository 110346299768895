import { formatMoney as money } from 'accounting'
import isNumber from 'lodash/isNumber'

export const formatMoney = (v, precision, symbol) => money(v, {
  precision: isNumber(precision) ? precision : 2,
  symbol,
})

export const convertGiBToBytes = (v) => (v ? v * Math.pow(1024, 3) : 0)
export const convertBytesToGiB = (v) => (v ? v / Math.pow(1024, 3) : 0)
export const convertBytesToMiB = (v) => (v ? v / Math.pow(1024, 2) : 0)

// 2 digits after dot, comma after every 3 digits
// eslint-disable-next-line max-len
export const formatCredit = (balance, fractionDigits) => formatMoney(balance, fractionDigits, 'CR ')
