const graphqlURL = process.env.REACT_APP_GRAPHQL_URL

export const creditsPerDollar = 1000
export const pricePerGiB = 0.1
// eslint-disable-next-line max-len
export const getAPIUrl = () => (graphqlURL || (typeof window !== 'undefined' ? `//${window.location.host}` : null))

export const siteMetadata = {
  title: 'Income by Spider.com',
  description: 'Earn money doing nothing',
  keywords: ['earn', 'passive income', 'income', 'money', 'windows', 'proxy'],
}

// eslint-disable-next-line max-len
export const googleClientID = process.env.REACT_APP_GOOGLE_APP_ID || '151326724776-t8h88u31llmp78an6fsttnahpcckdiqj.apps.googleusercontent.com'
export const facebookAppID = process.env.REACT_APP_FACEBOOK_APP_ID || '283884053690156'
