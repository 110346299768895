import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import SignupEmail from './SignupEmail'
import SigninEmail from './SigninEmail'
import ResetPassword from './ResetPassword'
import SetPassword from './SetPassword'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useAuth } from '../../context/auth'

export default () => {
  const { user } = useAuth()
  if (user) {
    return <Redirect to="/dashboard" />
  }

  return (
    <Switch>
      <Route exact path="/auth/signin-email" component={SigninEmail} />
      <Route exact path="/auth/signup-email" component={SignupEmail} />
      <Route exact path="/auth/signin" component={SigninEmail} />
      <Route exact path="/auth/signup" component={SignupEmail} />
      <Route exact path="/auth/reset-password" component={ResetPassword} />
      <Route exact path="/auth/change-password/:token" component={SetPassword} />
      <Route>
        <Redirect to="/auth/signin" />
      </Route>
    </Switch>
  )
}
