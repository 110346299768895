import React, { useState } from 'react'
import {
  gql,
} from '@apollo/client'
import { clientAuth } from '../graphql'

const AuthContext = React.createContext({})
const authKey = 'auth-1'

const authDefaults = {
  user: null,
  token: null,
  refresh: null,
}

const savePersistence = (data) => {
  localStorage.setItem(authKey, JSON.stringify(data))
}

const loadPersistence = () => {
  let state
  try {
    state = JSON.parse(localStorage.getItem(authKey))
  } catch (e) {
    console.error(e)
  }
  if (!state) state = { ...authDefaults }
  return state
}

const apollo = clientAuth()

const RESET_PASSWORD = gql`
    mutation ResetPassword($email: String!) {
        resetPassword: resetPassword(input: { email: $email })
    }
`

const SET_PASSWORD = gql`
    mutation SetPassword($password: String!, $token: String!) {
        setPassword: setPassword(input: { password: $password, token: $token })
    }
`

const SIGNIN_FACEBOOK = gql`
    mutation SigninFacebook(
        $accessToken: String!,
        $referralCode: String
        $teamInvite: Boolean!
        $device: String,
    ) {
        signinFacebook: signinFacebook(input: {
            accessToken: $accessToken,
            referralCode: $referralCode,
            teamInvite: $teamInvite,
            device: $device,
        }) {
            token {
                token
                expiresAt
            }
            refresh {
                token
                expiresAt
            }
            me {
                email
                name
            }
        }
    }
`

const SIGNIN_GOOGLE = gql`
    mutation SigninGoogle(
        $accessToken: String!,
        $referralCode: String
        $teamInvite: Boolean!
        $device: String,
    ) {
        signinGoogle: signinGoogle(input: {
          accessToken: $accessToken,
          referralCode: $referralCode,
          teamInvite: $teamInvite,
          device: $device,
        }) {
            token {
                token
                expiresAt
            }
            refresh {
                token
                expiresAt
            }
            me {
                email
                name
            }
        }
    }
`

const SIGNIN_EMAIL = gql`
    mutation SigninEmail($email: String!, $password: String!, $device: String) {
        signinEmail: signinEmail(input: { email: $email, password: $password, device: $device }) {
            token {
                token
                expiresAt
            }
            refresh {
                token
                expiresAt
            }
            me {
                email
                name
            }
        }
    }
`

const SEND_VERIFY_EMAIL = gql`
    mutation SendVerifyEmail($email: String!) {
        sendVerifyEmail: sendVerifyEmail(input: { email: $email})
    }
`

const SIGNUP_EMAIL = gql`
    mutation SignupEmail(
        $name: String!,
        $email: String!,
        $password: String!, 
        $referralCode: String,
        $device: String,
        $teamInvite: Boolean!,
    ) {
        signupEmail: signupEmail(input: {
            name: $name, 
            email: $email, 
            password: $password, 
            referralCode: $referralCode, 
            device: $device,
            teamInvite: $teamInvite
        })
    }
`

const VERIFY_EMAIL = gql`
    mutation VerifyEmail($token: String!) {
        verifyEmail: verifyEmail(input: { token: $token}) {
            token {
                token
                expiresAt
            }
            refresh {
                token
                expiresAt
            }
            me {
                email
                name
            }
        }
    }
`

const SIGNIN_TOKEN = gql`
    mutation signinToken($token: String!) {
        signinToken: signinToken(input: {token: $token}) {
            token {
                token
                expiresAt
            }
            refresh {
                token
                expiresAt
            }
            me {
                email
                name
            }
        }
    }
`

const REFRESH = gql`
    mutation refresh($token: String!) {
        refresh: refresh(input: {token: $token}) {
            token {
                token
                expiresAt
            }
            refresh {
                token
                expiresAt
            }
            me {
                email
                name
            }
        }
    }
`

const AuthProvider = ({ children }) => {
  const persistence = loadPersistence()
  const [state, setState] = useState(persistence)

  const handleLoginSuccess = ({
    token, refresh, me, onSuccess,
  }) => {
    const authData = { token, refresh, user: me }
    if (onSuccess) {
      onSuccess(authData)
    }
    savePersistence(authData)
    setState(authData)
  }

  const handleLoginError = ({ error, onError }) => {
    if (onError) {
      onError(error)
    }
  }

  const signupEmail = ({
    name, email, password, referralCode, teamInvite, device, onSuccess, onError,
  }) => apollo
    .mutate({
      mutation: SIGNUP_EMAIL,
      variables: {
        name, email, password, referralCode, teamInvite, device,
      },
    })
    .then(
      ({
        data: {
          signupEmail: {
            token, refresh, me,
          },
        },
      }) => {
        handleLoginSuccess({
          token, refresh, me, onSuccess,
        })
      },
    )
    .catch((error) => {
      handleLoginError({ error, onError })
    })

  const verifyEmail = ({
    tkn, onSuccess, onError,
  }) => apollo.mutate({
    mutation: VERIFY_EMAIL,
    variables: {
      token: tkn,
    },
  })
    .then(
      ({
        data: {
          verifyEmail: {
            token, refresh, me,
          },
        },
      }) => {
        handleLoginSuccess({
          token, refresh, me, onSuccess,
        })
      },
    )
    .catch((error) => {
      handleLoginError({ error, onError })
    })

  const signinGoogle = ({
    accessToken,
    referralCode,
    teamInvite,
    device,
    onSuccess,
    onError,
  }) => apollo
    .mutate({
      mutation: SIGNIN_GOOGLE,
      variables: {
        accessToken, referralCode, teamInvite, device,
      },
    })
    .then(
      ({
        data: {
          signinGoogle: {
            token, refresh, me,
          },
        },
      }) => {
        handleLoginSuccess({
          token, refresh, me, onSuccess,
        })
      },
    ).catch((error) => {
      handleLoginError({ error, onError })
    })

  const signinFacebook = ({
    accessToken,
    referralCode,
    teamInvite,
    device,
    onSuccess,
    onError,
  }) => apollo
    .mutate({
      mutation: SIGNIN_FACEBOOK,
      variables: {
        accessToken, referralCode, teamInvite, device,
      },
    })
    .then(
      ({
        data: {
          signinFacebook: {
            token, refresh, me,
          },
        },
      }) => {
        handleLoginSuccess({
          token, refresh, me, onSuccess,
        })
      },
    ).catch((error) => {
      handleLoginError({ error, onError })
    })

  const signinEmail = ({
    email, password, device, onSuccess, onError,
  }) => apollo
    .mutate({ mutation: SIGNIN_EMAIL, variables: { email, password, device } })
    .then(
      ({
        data: {
          signinEmail: {
            token, refresh, me,
          },
        },
      }) => {
        handleLoginSuccess({
          token, refresh, me, onSuccess,
        })
      },
    ).catch((error) => {
      handleLoginError({ error, onError })
    })

  const signinToken = ({ tkn, onSuccess, onError }) => apollo.mutate({
    mutation: SIGNIN_TOKEN, variables: { token: tkn },
  }).then(({
    data: {
      signinToken: {
        token, refresh, me,
      },
    },
  }) => {
    handleLoginSuccess({
      token, refresh, me, onSuccess,
    })
  }).catch((error) => {
    handleLoginError({ error, onError })
  })

  const refreshSession = () => apollo.mutate({
    mutation: REFRESH, variables: { token: state.refresh.token },
  }).then(({
    data: {
      refresh: {
        token, refresh, me,
      },
    },
  }) => {
    handleLoginSuccess({ token, refresh, me })
  }).catch((error) => {
    handleLoginError({ error })
  })

  const signout = () => {
    savePersistence({ ...authDefaults })
    setState({ ...authDefaults })
  }

  const resetPassword = ({ email }) => apollo
    .mutate({ mutation: RESET_PASSWORD, variables: { email } })

  const sendVerifyEmail = ({ email }) => apollo.mutate({
    mutation: SEND_VERIFY_EMAIL, variables: { email },
  })

  const setPassword = ({ password, token }) => apollo
    .mutate({ mutation: SET_PASSWORD, variables: { password, token } })

  const value = {
    signinEmail,
    signupEmail,
    signinGoogle,
    signinFacebook,
    signout,
    signinToken,
    resetPassword,
    setPassword,
    sendVerifyEmail,
    verifyEmail,
    refresh: refreshSession,
    ...state,
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => React.useContext(AuthContext)
export { AuthProvider, useAuth }
