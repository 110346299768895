import React from 'react'
import { Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useAuth } from '../../context/auth'

const SetReferrerCookie = ({ match: { params: { refCode } } }) => {
  const { user } = useAuth()
  if (user) {
    return <Redirect to="/dashboard" />
  }
  const learnMoreLink = `https://www.spider.com/income/learn-more?ref=${refCode}`
  Cookies.set('refCode', refCode, { expires: 100, path: '/' })
  window.location.href = learnMoreLink
  return null
}

export default SetReferrerCookie
