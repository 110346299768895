import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import {
  Row, Col, Button,
} from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

export default ({ initialValues, onRangeSelected }) => {
  const [dateRange, setDateRange] = useState([initialValues.startDate, initialValues.endDate])
  const [startDate, endDate] = dateRange

  const dateFilter = (date) => date < moment().add(1, 'days')
  return (
    <Row>
      <Col md="6">
        <ReactDatePicker
          placeholderText="Select desired date range"
          className="form-control"
          selectsRange="true"
          dateFormat="dd/MM/yyyy"
          startDate={startDate}
          endDate={endDate}
          filterDate={dateFilter}
          minDate={moment().subtract(1, 'months')}
          maxDate={moment().add(1, 'days')}
          onChange={(update) => {
            setDateRange(update)
            if (update[1]) {
              onRangeSelected({
                startDate: update[0],
                endDate: update[1],
              })
            }
          }}
          withPortal
        />
      </Col>
      <Col md="3">
        <div className="d-grid">
          <Button
            variant="link"
            onClick={() => {
              const startOfTheMonth = moment().startOf('month').toDate()
              const today = moment().toDate()
              setDateRange([startOfTheMonth, today])
              onRangeSelected({ startDate: startOfTheMonth, endDate: today })
            }}
          >
            This month
          </Button>
        </div>
      </Col>
      <Col md="3">
        <div className="d-grid">
          <Button
            variant="link"
            onClick={() => {
              const startOfTheLastMonth = moment().subtract(1, 'month').startOf('month').toDate()
              const endOfTheLastMonth = moment().subtract(1, 'month').endOf('month').toDate()
              setDateRange([startOfTheLastMonth, endOfTheLastMonth])
              onRangeSelected({ startDate: startOfTheLastMonth, endDate: endOfTheLastMonth })
            }}
          >
            Last month
          </Button>
        </div>
      </Col>
    </Row>
  )
}
