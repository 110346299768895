import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Redirect } from 'react-router-dom'
import SetPasswordForm from './components/SetPasswordForm'
import { useAuth } from '../../context/auth'
import Layout from './components/Layout'
import TopBar from '../layout/Topbar'
import AuthLinks from '../layout/components/AuthLinks'

const SetPassword = ({ match: { params: { token } } }) => {
  const [success, setSuccess] = useState(false)
  const { user, setPassword } = useAuth()
  if (user) {
    return <Redirect to="/dashboard" />
  }

  if (success) {
    return <Redirect to="/auth/signin" />
  }

  const handleSubmit = async ({ password }, { setSubmitting, setStatus }) => {
    try {
      setSubmitting(true)
      await setPassword({ password, token })
      setStatus({ msg: 'Password changed successfully.', ok: true })
      setSuccess(true)
    } catch (e) {
      const message = e.response ? e.response.data.message : 'Something went wrong. Please, try again.'
      setStatus({ msg: message, ok: false })
      setSubmitting(false)
    }
  }

  return (
    <Layout>
      <Container>
        <TopBar>
          <AuthLinks />
        </TopBar>
        <Row className="mt-5">
          <Col md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }}>
            <Formik
              onSubmit={handleSubmit}
              initialValues={{ password: '', confirm_password: '' }}
              validationSchema={Yup.object({
                password: Yup.string().required('Password is required').min(8, 'At least 8 symbols are required'),
                confirm_password: Yup.string()
                  .required('Please, confirm your password')
                  .oneOf([Yup.ref('password'), null], 'Passwords must match'),
              })}
            >
              {(props) => <SetPasswordForm title="Set new password" {...props} />}
            </Formik>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default SetPassword
