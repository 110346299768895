import React from 'react'
import { Card, Container, Row } from 'react-bootstrap'
import TransactionsTable from './TransactionsTable'
import Header from '../../components/Header'
import TransactionsChartWrapper from './TransactionsChartWrapper'

export default () => (
  <Container>
    <Header title="Transactions" />
    <Row className="mt-3">
      <Card>
        <Card.Body>
          <h3 className="mb-3">Over last month</h3>
          <TransactionsChartWrapper />
        </Card.Body>
      </Card>
    </Row>
    <Row className="mt-3">
      <TransactionsTable />
    </Row>
  </Container>
)
