import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import Header from '../../components/Header'
import TopBar from '../layout/Topbar'
import Layout from './components/Layout'
import Loader from '../../components/Loader'
import ResendVerifyEmail from './components/ResendVerifyEmail'
import AuthLinks from '../layout/components/AuthLinks'

export default ({ match: { params: { token } } }) => {
  const [error, setError] = useState(null)
  const [verified, setVerified] = useState(true)
  const { user, verifyEmail } = useAuth()
  useEffect(() => {
    verifyEmail({
      tkn: token,
      onError(e) {
        if (Array.isArray(e.graphQLErrors) && e.graphQLErrors.length) {
          setError(e.graphQLErrors[0].message)
        } else {
          setError('Oops.. could not verify email')
        }
      },
      onSuccess() {
        setVerified(true)
      },
    })
  }, [token])

  if (user && verified) {
    return <Redirect to="/" />
  }

  return (
    <Layout>
      <Container>
        <Header title="Sign up" />
        <TopBar>
          <AuthLinks />
        </TopBar>
        <Row className="mt-5">
          <Col md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }}>
            {!error && (
              <div className="mt-5">
                <h2 className="text-center mb-4 main-color">Verifying your email</h2>
                <Loader />
              </div>
            )}
            {error && <ResendVerifyEmail message={error} />}
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
