import React from 'react'
import moment from 'moment'
import get from 'lodash/get'
import { convertBytesToMiB } from '../../../format'
import './DeviceRow.css'
import EditDeviceAction from './EditDeviceAction'
import DeleteDeviceAction from './DeleteDeviceAction'

export default ({
  device: {
    id,
    updatedAt,
    identifier,
    details,
  },
  refetchQueries,
}) => (
  <tr>
    <td style={{ fontSize: '14px' }}>{identifier}</td>
    <td>{`${convertBytesToMiB(get(details, 'bandwidthSold', 0)).toFixed(1)} MiB`}</td>
    <td>
      {moment(updatedAt).format('L')}
    </td>
    <td className="text-start action">
      <EditDeviceAction
        id={id}
        className="m-1"
        identifier={identifier}
        refetchQueries={refetchQueries}
      />
      {moment(updatedAt).isBefore(moment().subtract(7, 'days'))
        && (
        <DeleteDeviceAction
          id={id}
          className="m-1"
          identifier={identifier}
          bandwidthSold={get(details, 'bandwidthSold', 0)}
          refetchQueries={refetchQueries}
        />
        )}
    </td>
  </tr>
)
