import { Route } from 'react-router-dom'
import React from 'react'

// eslint-disable-next-line no-nested-ternary
const NestedRoute = (item) => ((!!item.link && !!item.component)
  ? (
    <Route
      key={item.id}
      exact={item.exact}
      path={item.link}
      component={item.component}
    />
  )
  : item.children ? item.children.map(NestedRoute) : null)

export default NestedRoute
