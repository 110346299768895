import {
  ApolloClient, ApolloLink, HttpLink, InMemoryCache, from,
} from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { onError as apolloOnError } from '@apollo/client/link/error'
import fetch from 'isomorphic-fetch'
import get from 'lodash/get'
import { getAPIUrl } from './config'

const GRAPHQL_URL = getAPIUrl()

const apolloClient = ({
  path, token, onError,
}) => {
  const httpLink = new HttpLink({
    uri: `${GRAPHQL_URL}/${path}/`,
    fetch,
  })

  const authMiddleware = new ApolloLink((operation, forward) => {
    if (token) {
      const header = `Bearer ${token}`
      operation.setContext({
        headers: {
          Authorization: header,
        },
      })
    }

    return forward(operation)
  })

  const errorMiddleware = apolloOnError(({ networkError }) => {
    if (networkError) {
      if (get(networkError, 'statusCode', 422) !== 422 || get(networkError, 'response.status', 422) !== 422) {
        onError()
      }
    }
  })

  const retryLink = new RetryLink({ attempts: { max: 2 } })
  const link = from([authMiddleware, retryLink, errorMiddleware, httpLink])
  return new ApolloClient({ link, cache: new InMemoryCache() })
}

export const clientAuth = () => apolloClient({ onError: console.error, path: 'gql/auth' })
export const clientMain = ({ token, onError }) => apolloClient({ onError, token, path: 'gql/customer' })
