import React from 'react'
import moment from 'moment'
import lodash from 'lodash'
import { convertBytesToMiB } from '../../format'
import BandwidthChart from './components/BandwidthChartArea'

export default ({ bandwidth }) => {
  if (!bandwidth) {
    return (
      <div>
        <span className="d-block" style={{ minHeight: '130px', fontSize: '14px' }}>
          No bandwidth sent through your devices.
          <br />
          Please make sure your device is active.
        </span>
      </div>
    )
  }
  const bandwidthByHour = lodash(bandwidth.map((b) => ({
    value: convertBytesToMiB(b.value),
    time: moment(b.time).startOf('hour'),
  }))).groupBy('time').map((objs, key) => ({
    time: key,
    value: lodash.sumBy(objs, 'value'),
  })).value()
  return <BandwidthChart data={bandwidthByHour} xKey="time" yKey="value" />
}
