import { toast, Zoom } from 'react-toastify'
import './Notify.css'

export default {
  info: (text, timeout) => toast.info(text, {
    position: 'bottom-right',
    autoClose: timeout || 1000,
    className: 'msg-info',
    transition: Zoom,
    hideProgressBar: true,
  }),
  error: (text, timeout) => toast.error(text, {
    position: 'bottom-right',
    autoClose: timeout || 1000,
    transition: Zoom,
    hideProgressBar: true,
  }),
  warn: (text, timeout) => toast.warn(text, {
    position: 'bottom-right',
    autoClose: timeout || 1000,
    transition: Zoom,
    hideProgressBar: true,
  }),
}
