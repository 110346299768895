import React, { useState } from 'react'
import copy from 'copy-to-clipboard'
import {
  Col, FormCheck, FormControl, InputGroup, OverlayTrigger, Row, Tooltip,
} from 'react-bootstrap'
import { Clipboard } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import notify from '../../components/Notify'

const URLToShareWidget = ({ url, team }) => {
  const [referral, setReferral] = useState(url)
  const copyToClipboard = (text) => () => {
    notify.info('Ready to paste')
    copy(text)
  }

  return (
    <>
      <span className="text-muted ms-1">
        Your referral link
      </span>
      <Col md={6} lg={6} xs={10}>
        <InputGroup>
          <FormControl
            placeholder="referral"
            aria-label="referral"
            aria-describedby="basic-addon1"
            value={referral}
            disabled
          />
          <InputGroup.Text id="referral">
            <OverlayTrigger overlay={<Tooltip id="tooltip">Click to copy</Tooltip>}>
              <Clipboard color="#2F7EF2" onClick={copyToClipboard(referral)} />
            </OverlayTrigger>
          </InputGroup.Text>
        </InputGroup>
      </Col>
      {team && (
        <Row>
          <Col md={6} lg={6} xs={10} className="ms-1 mt-1 text-muted d-flex">
            <FormCheck
              type="checkbox"
              id="team-link"
              label="Ask referee to join"
              onClick={({ target: { checked } }) => {
                setReferral(`${url}${checked ? '/t' : ''}`)
              }}
            />
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="plain ms-1"
              to={`/teams/${team.id}`}
            >
              {team.name}
            </Link>
          </Col>
        </Row>
      )}
    </>
  )
}

export default URLToShareWidget
