import React from 'react'
import {
  Card, Container, Row,
} from 'react-bootstrap'
import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import Header from '../../components/Header'
import Loader from '../../components/Loader'

import { QUERY } from '../account'
import URLToShareWidget from './URLToShareWidget'

export default () => {
  const { loading, error, data } = useQuery(QUERY)
  if (error) {
    console.log(error)
  }
  if (loading || error) {
    return <Loader />
  }

  const { currentUser } = data
  const { handle } = currentUser
  const url = `${window.location.origin}/r/${handle}`
  const team = get(currentUser, 'teamMember.team', undefined)

  return (
    <Card>
      <Header title="Referrals" />
      <Card.Body>
        <Container>
          <Row className="mt-4">
            <h5>Referral information</h5>
          </Row>
          <Row className="mt-3">
            <p className="mb-0">
              Each direct referral earns&nbsp;
              <b>25%</b>
              {' '}
              of their referral’s direct earnings.
            </p>
            <p className="mb-0">
              Second Level referral earn&nbsp;
              <b>10%</b>
              {' '}
              of their referral’s direct earnings.
            </p>
            <p className="mb-0">
              Levels 3-4 will earn&nbsp;
              <b>5%</b>
            </p>
            <p className="mb-0">
              Level 5-10 will earn&nbsp;
              <b>1%</b>
            </p>
            <p className="mb-0">Level 11 will not earn anything</p>
          </Row>
          <Row className="mt-3">
            <p>* A user must directly earn $3 a month to be eligible to earn referral income</p>
          </Row>
          <Row
            className="mt-3"
          >
            <URLToShareWidget url={url} team={team} />
          </Row>
        </Container>
      </Card.Body>
    </Card>
  )
}
