import React from 'react'
import {
  Col, Container, ProgressBar, Row,
} from 'react-bootstrap'
import { gql } from '@apollo/client'
import { formatCredit, formatMoney } from '../../../format'
import { creditsPerDollar } from '../../../config'
import WithdrawalRequestButton from '../../teams/edit/WithdrawalRequestButton'
import { USER_FIELDS } from '../../../graphql_queries'

const CURRENT_USER_QUERY = gql`
    ${USER_FIELDS}
    query CurrentUser {
        currentUser {
            ...UserFields
        }
    }
`

export default ({
  emails, overallBalance, withdrawalAmount, payoutRequested,
}) => {
  let progress = Number((overallBalance / withdrawalAmount) * 100).toFixed(2)
  progress = progress >= 100 ? 100 : progress

  return (
    <Container>
      <Row>
        <Col xs={12} lg={12} md={12}>
          <h4 className="mb-0">
            <b>
              {formatCredit(overallBalance * creditsPerDollar, 4)}
            </b>
          </h4>
          <p className="text-lowercase text-muted">{`equals ${formatMoney(overallBalance)}`}</p>
        </Col>
        <Col xs={12} lg={12} md={12}>
          <ProgressBar
            style={{ height: '2rem' }}
            now={progress}
            label={progress > 25 ? `${progress}%` : undefined}
          />
          {progress < 100 && (
            <p>
              <small className="float-end">
                (When you reach $20 you&apos;ll be able to withdraw)
              </small>
            </p>
          )}
        </Col>
      </Row>
      {progress === 100 && (
        <Row className="mt-2">
          <Col xs={12} lg={12} md={12}>
            <WithdrawalRequestButton
              emails={emails}
              payoutRequested={payoutRequested}
              balance={overallBalance}
              withdrawalAmount={withdrawalAmount}
              refetchQueries={[{ query: CURRENT_USER_QUERY }]}
            />
          </Col>
        </Row>
      )}
    </Container>
  )
}
