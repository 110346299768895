import {
  Area,
  Bar, ComposedChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend,
} from 'recharts'
import moment from 'moment'
import React from 'react'
import { formatMoney } from '../../format'

const colors = ['#8884d8', '#82ca9d', '#ffc658', '#aaa344']

export default ({ data, xKey }) => (
  <ResponsiveContainer
    height={300}
  >
    <ComposedChart
      height={300}
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey={xKey}
        tickFormatter={(tick) => moment(tick).format('DD-MMM')}
      />
      <YAxis
        tickFormatter={formatMoney}
      />
      <Tooltip
        labelFormatter={(label) => moment(label).format('DD-MMM')}
        formatter={(x) => formatMoney(x, 5)}
      />
      <Legend />
      <Area
        dataKey="referrals"
        stackId="transactions"
        fill={colors[3]}
        stroke={colors[3]}
      />
      <Area
        dataKey="bandwidth"
        stackId="transactions"
        stroke={colors[2]}
        fill={colors[2]}
      />
      <Bar
        dataKey="time online"
        stroke={colors[1]}
        fill={colors[1]}
      />
      <Bar
        dataKey="bonus"
        stroke={colors[0]}
        fill={colors[0]}
      />
    </ComposedChart>
  </ResponsiveContainer>
)
