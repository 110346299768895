import React from 'react'
import {
  Alert, Button, Col, Form, Row,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './LoginForm.css'

const ResetPasswordForm = ({
  values, errors, status, title, handleSubmit, handleChange, handleBlur,
}) => (
  <Form className="form-wrapper" onSubmit={handleSubmit}>
    <h2 className="text-center mt-4 form-title">{title}</h2>
    <h6 className="text-center mt-4">
      Trouble Logging In? Enter your email and we&#39;ll send you a link to get back into your account.
    </h6>
    <Row>
      <Form.Group as={Col} md="12" sm="12">
        <Form.Label htmlFor="email">Email</Form.Label>
        <Form.Control
          type="text"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!errors.email}
          name="email"
          required
        />
        {errors.email
          && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
      </Form.Group>
    </Row>
    {status && (
      <Row className="mt-2">
        <Col>
          <Alert variant={status.ok ? 'success ' : 'danger'}>{status.msg}</Alert>
        </Col>
      </Row>
    )}
    <Row className="mt-2">
      <Form.Group as={Col} md="12" sm="12" className="mt-20 text-center">
        <Button
          variant="success"
          type="submit"
        >
          Submit
        </Button>
      </Form.Group>
    </Row>
    <Row className="mt-2">
      <Form.Group as={Col} md={12} sm={12} className="mt-20 text-center forgot-links text-14">
        <Link to="/auth/signup">Do not have an account?</Link>
      </Form.Group>
    </Row>
  </Form>
)

export default ResetPasswordForm
