import React from 'react'
import {
  Button, Col, Container, Row,
} from 'react-bootstrap'
import DownloadImage from '../../../assets/images/download-icon.svg'
import './Sidebar.css'

const Sidebar = ({ plain }) => (
  <Container fluid className="root vh-100">
    <Row><Col><h1>Earn $60 a month doing nothing!</h1></Col></Row>
    {!plain && (
    <Row className="mt-5">
      <Col>
        <Button variant="link" className="btn-link-2" href="/download" target="_blank" rel="noopener noreferrer">
          <img alt="Download Earn App" className="pb-1 pt-1" src={DownloadImage} />
          <span className="ms-2">Download app</span>
        </Button>
      </Col>
    </Row>
    )}
  </Container>
)

export default Sidebar
