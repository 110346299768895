import React from 'react'
import {
  Col, Image, Row,
} from 'react-bootstrap'
import Logo from '../../assets/images/logo.svg'
import './Topbar.css'

const TopBar = ({ children }) => (
  <Row id="topbar">
    <Col md={{ span: 3 }} lg={{ span: 3 }} xs={{ span: 3 }}>
      <span className="ms-3">
        <a href="https://www.spider.com/income" target="_blank" rel="noopener noreferrer">
          <Image id="logo" alt="Earn with Income" src={Logo} />
        </a>
      </span>
    </Col>
    <Col className="right-side" md={{ span: 5, offset: 4 }} lg={{ span: 5, offset: 4 }} xs={{ span: 5, offset: 4 }}>
      {children}
    </Col>
  </Row>
)

export default TopBar
